import FError from "../Common/ErrorHandling/FError"
import { appVersion } from "../Common/Functions/appVersion"
import { AnyProperty } from "../Common/Interfaces/AnyProperty"
import { AuthDoc } from "../Common/Interfaces/AuthDoc"
import { FBaseModelDoc } from "../Common/Interfaces/BaseDocs/FBaseModelDoc"
import { CreateDocumentRequestDoc } from "../Common/Interfaces/ServerDocs/CreateDocumentRequestDoc"
import { ServerErrorCodes } from "../Common/Interfaces/ServerDocs/ServerErrorCodes"
import { ServerParamsDoc } from "../Common/Interfaces/ServerParamsDoc"
import { ServerPayloadDoc } from "../Common/Interfaces/ServerPayloadDoc"
import { isString } from "../Common/is/isString"
import { ServerMethods } from "../Common/ServerMethods"
import { getBaseURL } from "../Common/ServerURLs/getBaseURL"
import { getPath } from "../FAxios/getPath"
import { AxiosMethodTypes } from "../Common/ServerMethodsObj/AxiosMethodTypes"
import { AxiosRequestInterface } from "../FAxios/AxiosRequestInterface"
import { fAxios } from "../FAxios/FAxios"

const method = ServerMethods.createDocument
export const handleCreateDocument = async (
    _authDoc: AuthDoc | null,
    requestDoc: CreateDocumentRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    ): Promise<FBaseModelDoc[]> => {
        // console.log('In handleCreateDocument', requestDoc);
    const payload: ServerPayloadDoc = {
        serverParamsDoc,
        appVersion: appVersion(),
        requestDoc: requestDoc as unknown as AnyProperty,
        method,
    }
    const baseURL = getBaseURL(method, serverParamsDoc)
    const path = getPath(method, serverParamsDoc);
    // console.log('In handleCreateDocument, baseURL: ', baseURL, 'Path: ', path)
    if (baseURL && isString(path)) {
        const {subDescriptor: {modelType}} = requestDoc;
        const request: AxiosRequestInterface = {
            method: AxiosMethodTypes.post,
            path: `${path}/${modelType}`,
            baseURL: baseURL,
            postPayload: {payload},
        }
        // console.log('In handleCreateDOcument REquest is :', request)
        return fAxios<FBaseModelDoc[]>(request)
    }
    const message = {
        message: `Failed to get baseURL ${baseURL} or Path ${path}`,
        serverParamsDoc: serverParamsDoc || {},
    }
    console.log('Throwing FError', message)
    throw new FError({
        message,
        method,
        code: ServerErrorCodes.InvalidData,
    });
}
