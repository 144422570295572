import {AuthDoc} from "../Common/Interfaces/AuthDoc";
import {FBaseModelDoc} from "../Common/Interfaces/BaseDocs/FBaseModelDoc";
import {SendMessageRequestDoc}
  from "../Common/Interfaces/ServerDocs/General/SendMessageRequestDoc";
import {ServerParamsDoc} from "../Common/Interfaces/ServerParamsDoc";
import { appVersion } from "../Common/Functions/appVersion";
import { AnyProperty } from "../Common/Interfaces/AnyProperty";
import { ServerPayloadDoc } from "../Common/Interfaces/ServerPayloadDoc";
import { ServerMethods } from "../Common/ServerMethods";
import { getBaseURL } from "../Common/ServerURLs/getBaseURL";
import { getPath } from "../FAxios/getPath";
import { AxiosMethodTypes } from "../Common/ServerMethodsObj/AxiosMethodTypes";
import { AxiosRequestInterface } from "../FAxios/AxiosRequestInterface";
import { fAxios } from "../FAxios/FAxios";
import FError from "../Common/ErrorHandling/FError";
import { ServerErrorCodes } from "../Common/Interfaces/ServerDocs/ServerErrorCodes";

const method = ServerMethods.sendMessage;

export const sendMessage = (
    _authDoc: AuthDoc | null,
    requestDoc: SendMessageRequestDoc,
    serverParamsDoc: ServerParamsDoc): Promise<FBaseModelDoc[]> => {
        const payload: ServerPayloadDoc = {
            serverParamsDoc,
            appVersion: appVersion(),
            requestDoc: requestDoc as unknown as AnyProperty,
            method,
        }
        const baseURL = getBaseURL(method, serverParamsDoc)
        const path = getPath(method, serverParamsDoc);
        if (baseURL) {
            const request: AxiosRequestInterface = {
                method: AxiosMethodTypes.post,
                path: `${path}`,
                baseURL: baseURL,
                postPayload: {payload},
            }
            console.log(`In ${method} REquest is :`, request)
            return fAxios<FBaseModelDoc[]>(request)
        }
        const message = {
            message: `Failed to get baseURL`,
            serverParamsDoc: serverParamsDoc || {},
        }
        throw new FError({
            message,
            method: "FBActions.updateModel",
            code: ServerErrorCodes.InvalidData,
        });
};