/* eslint-disable no-invalid-this */
/* eslint-disable require-jsdoc */
import {AnyProperty} from "../Interfaces/AnyProperty";
import {ServerErrorCodes} from "../Interfaces/ServerDocs/ServerErrorCodes";
import {isAnyProperty} from "../is/isAnyProperty";
import {isNumber} from "../is/isNumber";
import {isString} from "../is/isString";
import {ResponseCode} from "./ResponseCode";

export interface ErrorPayload {
  message: string | AnyProperty
  method?: string | undefined | null
  responseCode?: ResponseCode
  code?: string | ServerErrorCodes | number | undefined | null
  name?: string | undefined | null
  details?: string | undefined | null
}

class FError extends Error {
    innerMessage: string | AnyProperty;
    method: string ;
    code: string | ServerErrorCodes | number | undefined | null
    responseCode: ResponseCode;
    details?: string | undefined | null
    constructor( params: ErrorPayload) {
      super(isString(params.message) ? params.message : "");
      const {message, method, code, name, responseCode, details} = params;
      this.innerMessage =
        isString(message) || isAnyProperty(message) ? message : "";
      this.method = isString(method) ? method : "";
      this.code =
        isString(code) || isNumber(code) ? code : ServerErrorCodes.NoCode;
      this.responseCode = responseCode || ResponseCode.InternalServerError;
      this.name = isString(name) ? name : "";
      this.details = isString(details) ? details : null;
    }
    payload = (): ErrorPayload => {
      return {
        message: this.innerMessage,
        method: this.method,
        responseCode: this.responseCode,
        code: this.code || ServerErrorCodes.NoCode,
        name: this.name || "",
      };
    }
    toJSON = (): string => {
      return JSON.stringify(this.payload());
    }
    toString = (): string => {
      // eslint-disable-next-line max-len
      return `${this.name}: In ${this.method}, got error: ${JSON.stringify(this.innerMessage)}. Code is: ${this.code}`;
    }
    static fbErrortoFError(
        error: unknown,
        method: string,
        responseCode: ResponseCode = ResponseCode.NotFound
    ): FError {
      if (error instanceof FError) {
        return error;
      }
      if (isString(error)) {
        return new FError({message: error, method, responseCode});
      } else if (isAnyProperty(error)) {
        const {message, code, name, details} = error;
        const scrubbed =
          isString(message) || isAnyProperty(message) ? message : "No message";
        return new FError({
          message: scrubbed,
          method,
          responseCode,
          name: isString(name) ? name : undefined,
          code: isString(code) || isNumber(code) ?
            code : ServerErrorCodes.NoCode,
          details: isString(details) ? details : null,
        });
      } else {
        return new FError({
          message: `${error}`,
          method,
          responseCode,
          name: null,
          code: ServerErrorCodes.NoCode,
        });
      }
    }
}

export default FError;
