/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {resolveFBPath} from "../Common/Functions/PathGeneration/resolveFBPath";
import {AuthDoc} from "../Common/Interfaces/AuthDoc";
import {FBaseModelDoc} from "../Common/Interfaces/BaseDocs/FBaseModelDoc";
import {ServerParamsDoc} from "../Common/Interfaces/ServerParamsDoc";
import {FBDocumentReference,
  FBDocumentSnapshot,
  FBQueryDocumentSnapshot,
  FBQuerySnapshot} from "../Mapping/firebaseMapping";
import {createCollectionRef} from "./createCollectionRef";
import {handleDocSnapshot} from "./handleDocSnapshot";
import {getOrCreateMyProfile} from "./getOrCreateMyProfile";
import {lookupEmail} from "./lookupEmail";
import {registerUser} from "./registerUser";
import {updateModel} from "./updateModel";
import {handleCreateDocument} from "./handleCreateDocument";
import {removeDocument} from "./removeDocument";
import {lookupBusinessByDomain} from "./lookupBusinessByDomain";
import {innerGetDocs} from "./innerGetDocs";
import {getList} from "./getList";
import {addOrRemoveBusinessPerson} from "./addOrRemoveBusinessPerson";
import {getOrCreateUnsubmittedOrder} from "./getOrCreateUnsubmittedOrder";
import {CreateDocumentRequestDoc} from "../Common/Interfaces/ServerDocs/CreateDocumentRequestDoc";
import {ListQueryRequestDoc} from "../Common/Interfaces/ServerDocs/ListQueryRequestDoc";
import {UpdateDocumentRequestDoc} from "../Common/Interfaces/ServerDocs/UpdateDocumentRequestDoc";
import {updateLocation} from "./updateLocation";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import {unblockPhoneNumber} from "./unblockPhoneNumber";
import {updateBusinessDomain} from "./updateBusinessDomain";
import {lookupDisplayName} from "./lookupDisplayName";
import {updateDisplayName} from "./updateDisplayName";
import {sendMessage} from "./sendMessage";
import FError from "../Common/ErrorHandling/FError";
import {ResponseCode} from "../Common/ErrorHandling/ResponseCode";
import {getOrCreateFollow} from "./getOrCreateFollow";
import {getOrCreateBusinessRoom} from "./getOrCreateBusinessRoom";
import {getOrCreatePrivateRoom} from "./getOrCreatePrivateRoom";
import {lookupUsersBusinessesByRole} from "./lookupUsersBusinessesByRole";
import {lookupUserProfileViaUID} from "./lookupUserProfileViaUID";
import {lookupSearchCenter} from "./lookupSearchCenter";
import {updateLocationName} from "./updateLocationName";
import {createPhoneNumber} from "../FBOrderActions/PhoneNumber/createPhoneNumber";
import {updatePhoneNumber} from "../FBOrderActions/PhoneNumber/updatePhoneNumber";
import {updatePhoneNumberTextEnabled} from "../FBOrderActions/PhoneNumber/updatePhoneNumberTextEnabled";
import {lookupUsersBusinessByRoleForABusiness} from "./lookupUsersBusinessByRoleForABusiness";
import {getOrCreateProductByBusiness} from "./getOrCreateProductByBusiness";
import {publishProduct} from "./publishProduct";
import {getOrCreateNewBusiness} from "./getOrCreateNewBusiness";
import {publishOrUnpublishBusiness} from "./publishOrUnpublishBusiness";
import {createNewOrderFromExistingOrder} from "./createNewOrderFromExistingOrder";
import {lookupBusinessPersonAtDomain}
    from "./lookupBusinessPersonAtDomain";
import {getDocument} from "./getDocument";
import {getListOfPhoneNumbers} from "./getListOfPhoneNumbers";
import {changeListOrder} from "./changeListOrder";
import {rate} from "./rate";
import {getOrCreateRating} from "./getOrCreateRating";
import {listRatings} from "./listRatings";
import {getOrCreateAlbum} from "./getOrCreateAlbum";
import {getUsersBusinessesByRole} from "./getUsersBusinessesByRole";
import { updateOrderSeries } from "./updateOrderSeries";
import { createStripePaymentIntent } from "./createStripePaymentIntent";
import { createStripeBusinessAccount } from "./createStripeBusinessAccount";
import { updateStripePaymentIntent } from "./updateStripePaymentIntent";
import { updateOrderInvoice } from "./updateOrderInvoice";
import { updateInvoiceTip } from "./updateInvoiceTip";

export const fbActions = {
    getListOfSnapshots: async (
        _authDoc: AuthDoc | null,
        requestDoc: ListQueryRequestDoc,
        serverParams: ServerParamsDoc): Promise<FBQueryDocumentSnapshot[]> => {
      const {subDescriptor: {modelPath}, queryDoc} = requestDoc;
        return innerGetDocs(
            modelPath, queryDoc, serverParams).then((snapshot: FBQuerySnapshot) => {
            const scrubbed: FBQueryDocumentSnapshot[] = []
            if (!snapshot.empty) {
                snapshot.forEach((fbDocument: FBQueryDocumentSnapshot) => {
                    if (fbDocument.exists()) {
                        scrubbed.push(fbDocument)
                    }
                })
            }
            return scrubbed
        }).catch((error) => {
            const method = "fbActions.getListOfSnapshots";
            throw FError.fbErrortoFError(error, method, ResponseCode.InternalServerError);
        });
    },
    createDocument: async (
        authDoc: AuthDoc | null,
        requestDoc: CreateDocumentRequestDoc,
        serverParamsDoc: ServerParamsDoc,
        ): Promise<FBaseModelDoc[]> => {
            return handleCreateDocument(authDoc, requestDoc, serverParamsDoc);
    },
    updateDocument: async (
        _authDoc: AuthDoc | null,
        requestDoc: UpdateDocumentRequestDoc,
        serverParams: ServerParamsDoc): Promise<FBaseModelDoc[]> => {
      const {descriptorDoc: {modelPath, fuid}, updateDoc: updates} = requestDoc;
        const docRef: FBDocumentReference =
            doc(createCollectionRef(resolveFBPath(modelPath, serverParams)), fuid)
        return updateDoc(docRef, updates)
            .then(() => {
                return getDoc(docRef).then((snapshot: FBDocumentSnapshot) => {
                    return handleDocSnapshot(snapshot)
                })
            })
            .catch((error) => {
                const method = "fbActions.updateDocument";
                throw FError.fbErrortoFError(error, method, ResponseCode.InternalServerError);
            });                
    },
    getDocument,
    removeDocument,
    addOrRemoveBusinessPerson,
    getList,
    getOrCreateMyProfile,
    lookupEmail,
    registerUser,
    updateModel,
    lookupBusinessByDomain,
    getOrCreateUnsubmittedOrder,
    updateLocation,
    unblockPhoneNumber,
    updateBusinessDomain,
    lookupDisplayName,
    updateDisplayName,
    sendMessage,
    getOrCreateFollow,
    getOrCreateBusinessRoom,
    getOrCreatePrivateRoom,
    lookupUsersBusinessesByRole,
    lookupUserProfileViaUID,
    lookupSearchCenter,
    updateLocationName,
    createPhoneNumber,
    updatePhoneNumber,
    updatePhoneNumberTextEnabled,
    lookupUsersBusinessByRoleForABusiness,
    getOrCreateProductByBusiness,
    publishProduct,
    getOrCreateNewBusiness,
    publishOrUnpublishBusiness,
    createNewOrderFromExistingOrder,
    lookupBusinessPersonAtDomain,
    getListOfPhoneNumbers,
    changeListOrder,
    rate,
    getOrCreateRating,
    listRatings,
    getOrCreateAlbum,
    getUsersBusinessesByRole,
    updateOrderSeries,
    createStripePaymentIntent,
    createStripeBusinessAccount,
    updateStripePaymentIntent,
    updateOrderInvoice,
    updateInvoiceTip,
};

/*
       console.log('In createDocument Resolved path: ', resolveFBPath(modelPath, serverParams))
        const collectionRef =
            createCollectionRef(resolveFBPath(modelPath, serverParams))
        let docRef: FBDocumentReference
        if (isString(fuid)) {
            console.log(`In fbActions.createDOcument have existing fuid: ${fuid}`);
            docRef = doc(collectionRef, fuid)
            newDoc.fuid = fuid;
            newDoc.objectId = fuid;
            newDoc.ObjectId = fuid;
        } else {
            docRef = doc(collectionRef)
            const id = docRef.id
            newDoc.fuid = id
            newDoc.objectId = id
            newDoc.ObjectId = id
        }
    // eslint-disable-next-line max-len
    console.log(`About to create id: ${docRef.id}. newDoc: ${JSON.stringify(newDoc)}`);
        return setDoc(docRef, newDoc).then(() => {
            console.log('Hvae reresponse')
            return getDoc(docRef).then((snapshot: FBDocumentSnapshot) => {
                return handleDocSnapshot(snapshot)
            });
        }).catch((error) => {
            console.log('Got error', error)
            throw errorToErrorWithJSONMessage(error,
                'fbActions.createDocument', 'No_code')                  
        });
*/