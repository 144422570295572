import {FDescriptorDoc} from "../Interfaces/BaseDocs/FDescriptorDoc";
import {isFDescriptorDoc} from "../is/isFDescriptorDoc";

export const descriptorsEqual = (
    a: FDescriptorDoc | null | undefined,
    b: FDescriptorDoc | null | undefined): boolean => {
  if (isFDescriptorDoc(a) && isFDescriptorDoc(b)) {
    if ((a.modelType === b.modelType) &&
                (a.fuid === b.fuid)) {
      return true;
    }
  }
  return false;
};
