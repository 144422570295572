import { descriptorsEqual } from "../Common/Functions/descriptorsEqual"
import { FBaseModelDoc } from "../Common/Interfaces/BaseDocs/FBaseModelDoc"
import { FDescriptorDoc } from "../Common/Interfaces/BaseDocs/FDescriptorDoc"
import { isFDescriptorDoc } from "../Common/is/isFDescriptorDoc"

export const findBusiness = (
    docs: FBaseModelDoc[], 
    candidate: FDescriptorDoc | null | undefined
): boolean => {
    if (docs.length > 0 && isFDescriptorDoc(candidate)) {
        return docs.reduce((acc: boolean, element: FBaseModelDoc) => {
            if (acc === true) {
                return acc
            }
            return acc || descriptorsEqual(element, candidate)
        }, false)
    }
    return false
}