import { action, computed, createStore, StateMapper, thunk } from "easy-peasy";
import { FBaseModelDoc } from "../Common/Interfaces/BaseDocs/FBaseModelDoc";
import { FDescriptorDoc } from "../Common/Interfaces/BaseDocs/FDescriptorDoc";
import { FBAuthUser } from "../Mapping/firebaseMapping";
import { StoreModel } from "./StoreModel";
import { descriptorsEqual } from "../Common/Functions/descriptorsEqual";
import { WindowArea } from "./WindowModel";
import { NavigationMode } from "../NavigationInterface/NavigationMode";
import FOwner from "../Common/Classes/Models/FOwner";
import { SetRolePayload } from "./OwnerModel";
import { findBusiness } from "./findBusiness";
import FManager from "../Common/Classes/Models/FManager";
import FProvider from "../Common/Classes/Models/FProvider";
import { getAdmin } from "./getAdmin";
import { defaultOperatingMode } from "../config/defaultOperatingMode";
import { ServerMode } from "../Common/Interfaces/OperatingModes/ServerMode";
import { ClientMode } from "../Common/Interfaces/OperatingModes/ClientMode";
import { DBMode } from "../Common/Interfaces/OperatingModes/DBMode";
import { SelectedEntities } from "./SelectedEntityModel";
import { RouterRoles } from "../Common/Interfaces/Navigation/RouterRoles";
import { QueryParamsDoc } from "../Common/Interfaces/Query/QueryParamsDoc";
import { ModelFields } from "../Common/Interfaces/Models/ModelFields";
import { isFDescriptorDoc } from "../Common/is/isFDescriptorDoc";
import { AnyProperty } from "../Common/Interfaces/AnyProperty";
import { AuthDoc } from "../Common/Interfaces/AuthDoc";
import { OperatingModeDoc } from "../Common/Interfaces/OperatingModes/OperatingModeDoc";
import { NavigationProps } from "../NavigationInterface/NavigationProps";
import { fakeNavigationProps } from "../NavigationInterface/fakeNavigationProps";
import { DayWeekMonthOrYear } from "../Pages/Order/OrderCalendar/Scene/Component/CalendarTestComponent/DayWeekMonthOrYear";
import { DaysOfTheWeek } from "../Pages/Order/OrderCalendar/Scene/Component/CalendarTestComponent/DaysOfTheWeek";
import { zeroMilliseconds } from "../Pages/Calendar/zeroMilliseconds";
import { addDays, getDate, getHours, getMinutes, getMonth, getYear, setMinutes, setSeconds } from "date-fns";
import { DateComponents } from "../Pages/Order/OrderCalendar/Scene/Component/CalendarTestComponent/DateComponents";
import { assembleDate2 } from "../Pages/Order/OrderCalendar/Scene/Component/CalendarTestComponent/useDateInputs";
import addMonths from "date-fns/addMonths/index";

interface AuthState {
    user: FBAuthUser | null
    userProfile: FBaseModelDoc | null
    idToken: string | null
    isMe: (profileDoc: FDescriptorDoc | null | undefined) => boolean
}
const updatedTimestamp = (doc: QueryParamsDoc): QueryParamsDoc => {
    const { timestamp } = doc
    doc.timestamp = Math.floor((timestamp || new Date().getTime()  + new Date().getTime()) / 2) 
    return doc
}
/*

    incrementDateYear: Action<CalendarModel, number>
*/
const createDateObj = (date: Date): DateComponents => {
    return{day: getDate(date), month: getMonth(date), year: getYear(date), hours: getHours(date), minutes: getMinutes(date)}
}
const baseDate = zeroMilliseconds(setSeconds(setMinutes(new Date(), 0), 0))
const dateObj = createDateObj(baseDate)
export const store = createStore<StoreModel>({
    levelProps: {
        props: fakeNavigationProps,
        setProps: action((state, props: NavigationProps) => {
            return {props}
        })
    },
    calendarProps: {
        calendarMode: DayWeekMonthOrYear.week,
        anchorDay: DaysOfTheWeek.Monday,
        year: dateObj.year,
        month: dateObj.month,
        day: dateObj.day,
        hours: dateObj.hours,
        minutes: dateObj.hours,
        numberOfWeeks: 3,
        numberOfMonths: 1,
        setToSingleDay: action((state, date: Date) => {
            const year = getYear(date)
            const month = getMonth(date)
            const day = getDate(date)
            const hour = getHours(date)
            const minutes = getMinutes(date)
            return {...state, year, month, day, hour, minutes, calendarMode: DayWeekMonthOrYear.day}
        }),
        setCalendarMode: action((state, calendarMode: DayWeekMonthOrYear) => {
            return {...state, calendarMode}
        }),
        setAnchorDay: action((state, anchorDay: DaysOfTheWeek) => {
            return {...state, anchorDay}
        }),
        setDateMinutes: action((state, newMinutes: number) => {
            const {year, month, day, hours, minutes} = createDateObj(assembleDate2({...state, minutes: newMinutes}))
            return {...state, year, month, day, hours, minutes }
        }),
        setDateHours: action((state, newHours: number) => {
            const {year, month, day, hours, minutes} = createDateObj(assembleDate2({...state, hours: newHours}))
            return {...state, year, month, day, hours, minutes }
        }),
        setDateDay: action((state, newDays: number) => {
            const {year, month, day, hours, minutes} = createDateObj(assembleDate2({...state, day: newDays}))
            return {...state, year, month, day, hours, minutes }
        }),
        setDateMonth: action((state, newMonth: number) => {
            const {year, month, day, hours, minutes} = createDateObj(assembleDate2({...state, month: newMonth}))
            return {...state, year, month, day, hours, minutes }
        }),
        setDateYear: action((state, newYear: number) => {
            const {year, month, day, hours, minutes} = createDateObj(assembleDate2({...state, year: newYear}))
            return {...state, year, month, day, hours, minutes }
        }),
        incrementDateDay: action((state, increment: number) => {
            const {year, month, day, hours, minutes} = createDateObj(addDays(assembleDate2(state), increment))
            return {...state, year, month, day, hours, minutes }
        }),
        incrementDateMonth: action((state, increment: number) => {
            const {year, month, day, hours, minutes} = createDateObj(addMonths(assembleDate2(state), increment))
            return {...state, year, month, day, hours, minutes }
        }),
        incrementDateYear: action((state, increment: number) => {
            const {year, month, day, hours, minutes} = createDateObj(addMonths(assembleDate2(state), increment))
            return {...state, year, month, day, hours, minutes }
        }),
        incrementNumberOfMonths: action((state, increment: number) => {
            const { numberOfMonths: previous } = state
            let updated = previous + increment
            if (updated < 1) {
                updated = 1
            } else if (updated > 5) {
                updated = 5
            }
            return {...state, numberOfMonths: updated }
        }),
        incrementNumberOfWeeks: action((state, increment: number) => {
            const { numberOfWeeks } = state
            return {...state, numberOfWeeks: numberOfWeeks + increment }
        }),
    },
    router: {
        businessDomain: '',
        routerRole: RouterRoles.generic,
        adminCode: null,
        authDoc: null,
        trace: null,
        setAdminCode: action((state, adminCode: string | null) => {
            return {...state, adminCode}
        }),
        setAuthDoc: action((state, authDoc: AuthDoc | null) => {
            return {...state, authDoc}
        }),
        setBusinessDomain: action((state, businessDomain: string) => {
            return {...state, businessDomain}
        }),
        setOperatingModeDoc: action((state, operatingModeDoc: OperatingModeDoc) => {
            return {...state, operatingModeDoc}
        }),
        setTrace: action((state, trace: AnyProperty[]) => {
            return {...state, trace}
        }),
        setRouterRole: action((state, routerRole: RouterRoles) => {
            return {...state, routerRole}
        }),
        getRouterState: computed((state) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            return (_fake: boolean) => { return state}
        }),
    },
    drawerMode: {
        open: false,
        setDrawer: action((state, open: boolean) => {
            return {open}
        })
    },
    personMode: {
        personMode: RouterRoles.generic,
        setPersonMode: action((state, personMode: RouterRoles) => {
            return {...state, personMode}
        })
    },
    searchParams: {
        params: {},
        setObject: action((state, payload) => {
            const { descriptor: { fuid }, doc } = payload
            const { params } = state;
            state.params = {
                ...params, [fuid]: updatedTimestamp(doc)
            } 
        }),
        getDoc: computed((state) => {
            return (descriptor) => {
                if (isFDescriptorDoc(descriptor)) {
                    return state.params[descriptor.fuid] || null
                }
                return null;
            }
        }),
        addDescriptorTerm: action((state, payload) => {
            const { params } = state
            const { descriptor: { fuid }, field, term } = payload
            const doc = state.params[fuid]
            if (doc) {
                if (field === ModelFields.customer) {
                    doc.customer = term
                } else if (field === ModelFields.providers) {
                    const array = doc.providers || []
                    if (isFDescriptorDoc(term)) {
                        const descriptors = array.filter((element) => {
                            return element.fuid !== fuid;
                        });
                        descriptors.push(term);
                        doc.providers = descriptors;
                    } else {
                        doc.providers = []
                    }

                } else if (field === ModelFields.business) {
                    doc.business = term
                } else if (field === ModelFields.owner) {
                    doc.owner = term
                } else {
                    console.log('Unhandled field')
                }
                state.params = {
                    ...params, [fuid]: updatedTimestamp(doc)
                }               
            }
        }),
        removeDescriptorTerm: action((state, payload) => {
            const { params } = state
            const { descriptor: { fuid }, field, term } = payload
            const doc = state.params[fuid]
            if (doc) {
                if (field === ModelFields.customer) {
                    doc.customer = null
                } else if (field === ModelFields.providers) {
                    const array = doc.providers
                    if (Array.isArray(array)) {
                        const termfuid = term.fuid
                        doc.providers = array.filter((entry) => {
                            return entry.fuid !== termfuid
                        })
                    }
                } else if (field === ModelFields.business) {
                    doc.business = null
                } else if (field === ModelFields.owner) {
                    doc.owner = null
                } else {
                    console.log('Unhandled field')
                }
                state.params = {
                    ...params, [fuid]: updatedTimestamp(doc)
                }
            }
        }),
    },
    operatingMode: {
        operatingModeDoc: defaultOperatingMode(),
        setClientMode: action((
            state,
            clientMode: ClientMode
        ) => {
            return {
                ...state,
                clientMode,
            }
        }),
        setServerMode: action((
            state,
            serverMode: ServerMode
        ) => {
            return {
                ...state,
                serverMode,
            }
        }),
        setDBMode: action((
            state,
            dbMode: DBMode
        ) => {
            return {
                ...state,
                dbMode
            }
        }),
    },
    auth: {
        user: null,
        userProfile: null,
        isMe: computed((state) => {
            return (profileDoc) => {
                return descriptorsEqual(state.userProfile, profileDoc);
            }
        }),
        setUserProfile: action(
            (
                state: StateMapper<AuthState>,
                payload: { userProfile: FBaseModelDoc | null },
            )  => {
                const { userProfile } = payload
                return {
                    ...state,
                    userProfile,
                }
            }
        ),
        setAuthUser: action(
            (
                state: StateMapper<AuthState>,
                payload: { user: FBAuthUser | null },
            ) => {
                const { user } = payload
                return {
                    ...state,
                    user,
                }
            },
        ),
        setAuthInfo: action(
            (
                state: StateMapper<AuthState>,
                payload: { user: FBAuthUser | null, userProfile: FBaseModelDoc | null },
            ) => {
                const { user, userProfile } = payload
                return {
                    ...state,
                    user,
                    userProfile,
                }
            },
        ),
        idToken: null,
        setIdToken: action(
            (
                state: StateMapper<AuthState>,
                idToken: string | null,
            ) => {
                state.idToken = idToken
            },
        ),
    },
    navMode: {
        navigationMode: NavigationMode.page,
        setNavigationMode: action((state: StateMapper<{ navigationMode: NavigationMode }>, mode: NavigationMode) => {
            state.navigationMode = mode
        }),
    },
    adminCode: {
        adminCode: 'Nope',
        setAdminCode: action((state: StateMapper<{ adminCode: string }>, code: string) => {
            state.adminCode = code
        }),
    },
    selectedProvider: {
        selectedProvider: null,
        setSelectedProvider: action((state: StateMapper<{ selectedProvider: FDescriptorDoc | null }>, provider: FDescriptorDoc | null) => {
            state.selectedProvider = provider
        }),
    },
    selectedEntities: {
        selectedOrder: null,
        selectedCustomer: null,
        selectedProvider: null,
        selectedManager: null,
        selectedOwner: null,
        selectedBusiness: null,
        setSelectedOrder: action((state: StateMapper<SelectedEntities>, orderDoc: FDescriptorDoc | null) => {
            state.selectedOrder = orderDoc
        }),
        setSelectedProvider: action((state: StateMapper<SelectedEntities>, providerDoc: FDescriptorDoc | null) => {
            state.selectedProvider = providerDoc
        }),
        setSelectedManager: action((state: StateMapper<SelectedEntities>, managerDoc: FDescriptorDoc | null) => {
            state.selectedManager = managerDoc
        }),
        setSelectedOwner: action((state: StateMapper<SelectedEntities>, ownerDoc: FDescriptorDoc | null) => {
            state.selectedOwner = ownerDoc
        }),
        setSelectedCustomer: action((state: StateMapper<SelectedEntities>, customerDoc: FDescriptorDoc | null) => {
            state.selectedCustomer = customerDoc
        }),
        setSelectedBusiness: action((state: StateMapper<SelectedEntities>, businessDoc: FDescriptorDoc | null) => {
            state.selectedBusiness = businessDoc
        }),
    },
    window: {
        window: { height: 300, width: 300 },
        setWindow: action(
            (
                state: StateMapper<{ window: WindowArea }>,
                payload: WindowArea,
            ) => {
                state.window = payload
            },
        ),
    },
    owner: {
        ownerDocs: [],
        businessDocs: [],
        docsLoaded: false,
        setDocsLoaded: action((state, loaded: boolean) => {
            state.docsLoaded = loaded
        }),
        isOwner: computed((state) => {
            return () => {
                return state.businessDocs.length > 0
            }
        }),
        isOwnerOfBusiness: computed((state) => {
            return (businessDoc: FDescriptorDoc | null | undefined): boolean => {
                return findBusiness(state.businessDocs, businessDoc)
            }
        }),
        setOwnerDocs: action((state, modelDocs: FBaseModelDoc[]) => {
            state.ownerDocs = modelDocs
        }),
        setBusinessDocs: action((state, modelDocs: FBaseModelDoc[]) => {
            state.businessDocs = modelDocs
        }),
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        asyncSetOwners: thunk(async(actions, payload: SetRolePayload) => {
            const {authDoc, ownerProfileDoc, serverParamsDoc} = payload;
            actions.setDocsLoaded(false)
            const businessDocs = await FOwner.getUsersBusinessesByRole(authDoc, serverParamsDoc)
            actions.setBusinessDocs(businessDocs)
            const ownerDocs = await FOwner
                .lookupUsersBusinessesByRole(authDoc, ownerProfileDoc, serverParamsDoc)
                    .catch((error) => {
                    console.log('Got error getting ownership', error)
                    return []
                })
            actions.setOwnerDocs(ownerDocs)
            actions.setDocsLoaded(true)
        }),
    },
    manager: {
        managerDocs: [],
        businessDocs: [],
        docsLoaded: false,
        setDocsLoaded: action((state, loaded: boolean) => {
            state.docsLoaded = loaded
        }),
        isManager: computed((state) => {
            return () => {
                return state.businessDocs.length > 0
            }
        }),
        isManagerOfBusiness: computed((state) => {
            return (businessDoc: FDescriptorDoc | null | undefined): boolean => {
                return findBusiness(state.businessDocs, businessDoc)
            }
        }),
        setManagerDocs: action((state, modelDocs: FBaseModelDoc[]) => {
            state.managerDocs = modelDocs
        }),
        setBusinessDocs: action((state, modelDocs: FBaseModelDoc[]) => {
            state.businessDocs = modelDocs
        }),
        asyncSetManagers: thunk(async(actions, payload: SetRolePayload)  => {
            const {authDoc, ownerProfileDoc, serverParamsDoc} = payload;
            actions.setDocsLoaded(false)
            const businessDocs = await FManager.getUsersBusinessesByRole(authDoc, serverParamsDoc)
                .catch((error) => {
                    console.log('GOt Error getting managers businesses', error)
                    return []
                })
            actions.setBusinessDocs(businessDocs)
            const managerDocs = await FManager
                .lookupUsersBusinessesByRole(authDoc, ownerProfileDoc, serverParamsDoc)
                    .catch((error) => {
                        console.log('Got error getting ownership', error)
                        return []
                    })
            // console.log('settingOwners', ownerDocs)
            actions.setManagerDocs(managerDocs)
            // console.log('settingBusinessess', businessDocs)
            actions.setDocsLoaded(true)
        }),
    },
    admin: {
        adminDocs: [],
        docsLoaded: false,
        setDocsLoaded: action((state, loaded: boolean) => {
            state.docsLoaded = loaded
        }),
        isAdmin: computed((state) => {
            return () => {
                return state.adminDocs.length > 0
            }
        }),
        setAdminDocs: action((state, modelDocs: FBaseModelDoc[]) => {
            state.adminDocs = modelDocs
        }),
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        asyncSetAdminDocs: thunk(async (actions, _uid: null) => {
            actions.setDocsLoaded(false)
            const adminDocs = await getAdmin().catch((error) => {
                console.log('Got error getting adminDocs', error)
                return []
            })
            actions.setAdminDocs(adminDocs)
            actions.setDocsLoaded(true)
        }),
    },
    provider: {
        providerDocs: [],
        businessDocs: [],
        isProvider: computed((state) => {
            return () => {
                return state.businessDocs.length > 0
            }
        }),
        isProviderOfBusiness: computed((state) => {
            return (businessDoc: FDescriptorDoc | null | undefined): boolean => {
                return findBusiness(state.businessDocs, businessDoc)
            }
        }),
        setProviderDocs: action((state, modelDocs: FBaseModelDoc[]) => {
            state.providerDocs = modelDocs
        }),
        setBusinessDocs: action((state, modelDocs: FBaseModelDoc[]) => {
            state.businessDocs = modelDocs
        }),
        docsLoaded: false,
        setDocsLoaded: action((state, loaded: boolean) => {
            state.docsLoaded = loaded
        }),
        asyncSetProviders: thunk(async(actions, payload: SetRolePayload) => {
            actions.setDocsLoaded(false);
            const {authDoc, ownerProfileDoc, serverParamsDoc} = payload;
            const businessDocs = await FProvider.getUsersBusinessesByRole(authDoc, serverParamsDoc)
                .catch((error) => {
                    console.log('Got error getting manager businessees', error);
                    return []
                })
            actions.setBusinessDocs(businessDocs)
            const providerDocs = await FProvider
            .lookupUsersBusinessesByRole(authDoc, ownerProfileDoc, serverParamsDoc)
                .catch((error) => {
                    console.log('Got error getting managers', error)
                    return []
                })
            // console.log('settingOwners', ownerDocs)
            actions.setProviderDocs(providerDocs)
            // console.log('settingBusinessess', businessDocs)
            actions.setDocsLoaded(true)
        }),
    },
})

/*
businessDomain: '',
routerRole: RouterRoles.generic,
adminCode: null,
trace: [],
authDoc: null,
operatingModeDoc: defaultOperatingMode(),
setAdminCode: action((state, adminCode: string | null) => {
    return {...state, adminCode}
}),
setAuthDoc: action((state, authDoc: AuthDoc | null) => {
    return {...state, authDoc}
}),
setBusinessDomain: action((state, businessDomain: string) => {
    return {...state, businessDomain}
}),
setOperatingModeDoc: action((state, operatingModeDoc: OperatingModeDoc) => {
    return {...state, operatingModeDoc}
}),
setTrace: action((state, trace: AnyProperty[]) => {
    return {...state, trace}
}),
setRouterRole: action((state, routerRole: RouterRoles) => {
    return {...state, routerRole}
}),

setProps: action((state, props: ServerParamsDocAndAuthDoc) => {
    return {...state, ...props}
}),
getRouterState: computed((state) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return (_fake: boolean) => { return state}
}),
*/