import { makeStyles, Theme } from '@material-ui/core';
// import { horizontalCenter } from '../../Styles';

// const publicBackgroundImage = '/Tesla.jpeg';
const footerHeight = 90;
const mobileTopBarHeight = 60;

const useStyles = makeStyles((theme: Theme) => {
  const topMargin = (typeof theme.mixins.toolbar.minHeight === typeof 1) ? theme.mixins.toolbar.minHeight : mobileTopBarHeight + 4;
    return ({
      outerContainer: {
          marginTop: '0px',
          marginLeft: '0px',
          marginRight: '0px',  
          width: '100%',
          height: `calc(100vh - ${(footerHeight + parseInt(`${topMargin}`))}px)`,
          overflow: 'hidden',
      },
      appBar: {
        width: '100%',
        height: 60,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      center2: {
        marginTop: 80,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginLeft: 'auto',
          marginRight: 'auto',
      },
      phoneContainer: {
       backgroundImage: 'url("iphonescreen.png")',
       height: '600px',
      },
      header: {
          marginTop: 0,
      },
      white: {
        color: 'white',
      },
      flex: {
        display: 'flex',
      },
      center: {
          marginLeft: 'auto',
          marginRight: 'auto',
      },
      buttonText: {
          textTransform: 'none'
      },
      buttonGap: {
          marginTop: 20,
      },
  }
)});

export default useStyles;

/*
      appBar: {
        [theme.breakpoints.up(mobileBreakpoint)]: {
          width: `calc(100% - ${drawerWidth}px)`,
          marginLeft: drawerWidth,
        },
        height: 50,
      },
      */