import FError from "../Common/ErrorHandling/FError";
import {AuthDoc} from "../Common/Interfaces/AuthDoc";
import {FBaseModelDoc} from "../Common/Interfaces/BaseDocs/FBaseModelDoc";
import {ServerErrorCodes}
  from "../Common/Interfaces/ServerDocs/ServerErrorCodes";
import {ServerParamsDoc} from "../Common/Interfaces/ServerParamsDoc";
import { CreateStripePaymentIntentRequestDoc }
  from "../Common/Interfaces/ServerDocs/Stripe/CreateStripePaymentIntentRequestDoc";
import { ServerMethods } from "../Common/ServerMethods";
import { ServerPayloadDoc } from "../Common/Interfaces/ServerPayloadDoc";
import { AnyProperty } from "../Common/Interfaces/AnyProperty";
import { appVersion } from "../Common/Functions/appVersion";
import { getBaseURL } from "../Common/ServerURLs/getBaseURL";
import { getPath } from "../FAxios/getPath";
import { AxiosRequestInterface } from "../FAxios/AxiosRequestInterface";
import { AxiosMethodTypes } from "../Common/ServerMethodsObj/AxiosMethodTypes";
import { fAxios } from "../FAxios/FAxios";

const method = ServerMethods.createStripePaymentIntent;
export const createStripePaymentIntent = (
    _authDoc: AuthDoc | null,
    requestDoc: CreateStripePaymentIntentRequestDoc,
    serverParamsDoc: ServerParamsDoc
): Promise<FBaseModelDoc[]> => {
    const payload: ServerPayloadDoc = {
        serverParamsDoc,
        appVersion: appVersion(),
        requestDoc: requestDoc as unknown as AnyProperty,
        method,
    }
    const baseURL = getBaseURL(method, serverParamsDoc)
    const path = getPath(method, serverParamsDoc);
    if (baseURL && path) {
        const request: AxiosRequestInterface = {
            method: AxiosMethodTypes.post,
            path,
            baseURL: baseURL,
            postPayload: {payload},
        }
        return fAxios<FBaseModelDoc[]>(request)
    }
    const message = {
      message: `Failed to get baseURL`,
      serverParamsDoc: serverParamsDoc || {},
    }
      throw new FError({
        message,
        method,
        code: ServerErrorCodes.InvalidData,
      });
    };

/*
const updateStripePaymentIntent = async (
    paymentIntent: AnyProperty,
    orderDoc: FDescriptorDoc,
    customerDoc: FDescriptorDoc,
    trace: string,
): Promise<AnyProperty[]> => {
    if (client) {
        const key = 'updateStripePaymentIntent'
        const methodName = getMethodName(key)
        if (methodName) {
            return handleFirebaseMethod<AnyProperty>(methodName, {
                doc: { orderDoc, customerDoc, paymentIntent },
                trace,
                params: {},
            })
        } else {
            throw new FAppError(`In fbActions.upateStripePaymentIntent failed to get methodName for ${key}`)
        }
    } else {
        console.log(`In fbAction.upateStripePaymentIntent. This should not be used on the server`)
        return Promise.resolve([])
    }
}
const createStripeBusinessAccount = async (businessDoc: FDescriptorDoc, trace: string): Promise<AnyProperty[]> => {
    if (client) {
        const key = 'createStripeBusinessAccount'
        const methodName = getMethodName(key)
        if (methodName) {
            return handleFirebaseMethod<AnyProperty>(methodName, {
                doc: { business: businessDoc },
                trace,
                params: {},
            })
        } else {
            throw new FAppError(`In fbActions.createStripeBusinessAccount failed to get methodName for ${key}`)
        }
    } else {
        console.log(`In fbAction.createStripeBusinessAccount. This should not be used on the server`)
        return Promise.resolve([])
    }
}

const updateOrderInvoice = async (orderDoc: FDescriptorDoc, trace: string): Promise<FBaseModelDoc[]> => {
    if (client) {
        const key = 'updateOrderInvoice'
        const methodName = getMethodName(key)
        if (methodName) {
            return handleFirebaseMethod<FBaseModelDoc>(methodName, {
                orderDoc,
                trace,
                params: {},
            })
        } else {
            throw new FAppError(`In fbActions.updateOrderInvoice failed to get methodName for ${key}`)
        }
    } else {
        console.log(`In fbAction.updateOrderInvoice. This should not be used on the server`)
        return Promise.resolve([])
    }
}

const updateInvoiceTip = async (orderDoc: FDescriptorDoc, tip: number, trace: string): Promise<FBaseModelDoc[]> => {
    if (client) {
        const key = 'updateInvoiceTip'
        const methodName = getMethodName(key)
        if (methodName) {
            return handleFirebaseMethod<FBaseModelDoc>(methodName, {
                doc: { orderDoc: FOrder.synthesizeDescriptor(orderDoc.fuid), tip },
                trace,
                params: {},
            })
        } else {
            throw new FAppError(`In fbActions.updateInvoiceTip failed to get methodName for ${key}`)
        }
    } else {
        console.log(`In fbAction.updateInvoiceTip. This should not be used on the server`)
        return Promise.resolve([])
    }
}
*/