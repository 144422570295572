import { ServerParamsDoc } from "../Common/Interfaces/ServerParamsDoc"
import { ServerMethods } from "../Common/ServerMethods"
import { ServerMethodsObj } from "../Common/ServerMethodsObj";

export const getPath = (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    serverMethod: ServerMethods, _serverParamsDoc: ServerParamsDoc,
    ): string | null => {
        const obj = ServerMethodsObj[serverMethod];
        if (obj) {
            const { prefix, suffix } = obj
            return `${prefix}${suffix}`
        }
        return null
    }

/*
import { ServerParamsDoc } from "../Common/Interfaces/ServerParamsDoc";
import { ServerMethods } from "../Common/ServerMethods";

export const getPath = (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    serverMethod: ServerMethods, _serverParamsDoc: ServerParamsDoc,
    ): string | null => {
        switch (serverMethod) {
            case ServerMethods.addOrRemoveBusinessPerson:
                return '/business/addorremovebusinessperson';
            case ServerMethods.cancelOrder:
                return '/order/cancelorder';
            case ServerMethods.createDocument:
                return '';
            case ServerMethods.createPhoneNumber:
                return '/phonenumber/create';
            case ServerMethods.deleteDocument:
                return '/delete';
            case ServerMethods.getDocument:
                return '/getdocument';
            case ServerMethods.getOrCreateBusinessRoom:
                return '/getorcreatebusinessroom';
            case ServerMethods.getOrCreateFollow:
                return '/getorcreatefollow';
            case ServerMethods.getOrCreateMyProfile:
                return '/profile/getorcreatemyprofile';
            case ServerMethods.getOrCreateNewBusiness:
                return '/business/getorcreatenewbusiness';
            case ServerMethods.getOrCreatePrivateRoom:
                return '/getorcreateprivateroom';
            case ServerMethods.getOrCreateProductByBusiness:
                return '/product/getorcreateproductbeingedited';
            case ServerMethods.getOrCreateUnsubmittedOrder:
                return '/order/getorcreateunsubmittedorder';
            case ServerMethods.initializeServer:
                return '/admin/initialize';
            case ServerMethods.lookupBusinessByDomain:
                return '/business/lookupbydomain';
            case ServerMethods.lookupDisplayName:
                return '/lookupdisplayname';
            case ServerMethods.lookupEmail:
                return '/auth/lookupemail';
            case ServerMethods.lookupSearchCenter:
                return '/location/lookupsearchcenter';
            case ServerMethods.lookupUserProfileViaUID:
                return '/auth/lookupuserprofileviauid';
            case ServerMethods.lookupUsersBusinessByRoleForABusiness:
                return '/business/lookupUsersBusinessByRoleForABusiness';
            case ServerMethods.lookupUsersBusinessesByRole:
                return '/lookupusersbusinessesbyrole'
            case ServerMethods.publishOrUnpublishBusiness:
                return '/business/publishorunpublishbusiness';
            case ServerMethods.publishProduct:
                return '/product/publishproduct';
            case ServerMethods.registerUser:
                return '/auth/registeruser';
            case ServerMethods.removeOrderProductFromOrder:
                return '/order/removeorderproduct';
            case ServerMethods.sendMessage:
                return '/sendmessage';
            case ServerMethods.submitOrder:
                return '/order/submit';
            case ServerMethods.updateBusinessDomain:
                return '/updatebusinessdomain';
            case ServerMethods.updateDisplayName:
                return '/updatedisplayname';
            case ServerMethods.updateLocation:
                return '/location/update';
            case ServerMethods.updateLocationName:
                return '/location/updatename';

            case ServerMethods.updateModel:
                return '';
            case ServerMethods.updateOrderLocation:
                return '/order/updateorderlocation';
            case ServerMethods.updateOrderProduct:
                return '/order/updateorderproduct';
            case ServerMethods.updateOrderProvider:
                return '/order/updateorderprovider';
            case ServerMethods.updateOrderStatus:
                return '/order/updateorderstatus';
            case ServerMethods.updateOrderTime:
                return '/order/updateordertime';
            case ServerMethods.updatePhoneNumber:
                return '/phonenumber/update';
            default:
                console.log('unhandled serverMethod', serverMethod);
        }
        return null;
}
*/