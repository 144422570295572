import { endAt, endBefore, limit, limitToLast, orderBy, query, startAfter, startAt, where } from "firebase/firestore"
import { arrayToDotPath } from "../Common/Functions/PathGeneration/arrayToDotPath"
import { FBQueryDoc } from "../Common/Interfaces/Query/FBQueryDoc"
import { isAnyProperty } from "../Common/is/isAnyProperty"
import { isNumber } from "../Common/is/isNumber"
import { FBCollectionReference,
    FBQuery,
    FBWhereFilterOp,
    FBOrderByDirection
} from "../Mapping/firebaseMapping"
import { createCollectionRef } from "./createCollectionRef"

// FirestoreErrorCode
// https://firebase.google.com/docs/firestore/query-data/order-limit-data

export const createQueryRef = (
    collectionPath: string[],
    queryDoc?: FBQueryDoc,
): FBCollectionReference | FBQuery => {
    let ref: FBCollectionReference | FBQuery = createCollectionRef(collectionPath)
    if (queryDoc !== undefined) {
        const { cursor, sortObjects, wheres } = queryDoc
        if (wheres.length > 0) {
            wheres.map((whereItem) => {
                const { path, operator, value } = whereItem
                ref = query(ref, where(arrayToDotPath(path), operator as FBWhereFilterOp, value))
            })
        }
        if (sortObjects.length > 0) {
            sortObjects.map((obj) => {
                const { path, order } = obj
                ref = query(ref, orderBy(arrayToDotPath(path), order as FBOrderByDirection))
            })
        }
        if (isAnyProperty(cursor)) {
            const keys = Object.keys(cursor)
            if (keys.length > 0) {
                if (keys.length > 1) {
                    console.error('In listFBDoc, erroneous have more than one cursor', cursor)
                }
                const key = keys[0]
                switch (key) {
                    case 'startAt':
                        ref = query(ref, startAt(cursor.startAt))
                        break
                    case 'startAfter':
                        ref = query(ref, startAfter(cursor.startAfter))
                        break
                    case 'endBefore':
                        ref = query(ref, endBefore(cursor.endBefore))
                        break
                    case 'endAt':
                        ref = query(ref, endAt(cursor.endAt))
                        break
                    default:
                        console.log('Got erroneous cursor field', key, cursor)
                }
            }
        }
        if (isNumber(queryDoc.limitToLast)) {
            ref = query(ref, limitToLast(queryDoc.limitToLast))
        } else if (isNumber(queryDoc.limit)) {
            ref = query(ref, limit(queryDoc.limit))
        }
        return ref
    } else {
        return ref
    }
}