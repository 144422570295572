import FError from "../Common/ErrorHandling/FError";
import { appVersion } from "../Common/Functions/appVersion";
import { AnyProperty } from "../Common/Interfaces/AnyProperty";
import {AuthDoc} from "../Common/Interfaces/AuthDoc";
import {FBaseModelDoc} from "../Common/Interfaces/BaseDocs/FBaseModelDoc";
import {UpdateOrderSeriesRequestDoc}
  from "../Common/Interfaces/ServerDocs/Order/UpdateOrderSeriesRequestDoc";
import {ServerErrorCodes}
  from "../Common/Interfaces/ServerDocs/ServerErrorCodes";
import {ServerParamsDoc} from "../Common/Interfaces/ServerParamsDoc";
import { ServerPayloadDoc } from "../Common/Interfaces/ServerPayloadDoc";
import { ServerMethods } from "../Common/ServerMethods";
import { AxiosMethodTypes } from "../Common/ServerMethodsObj/AxiosMethodTypes";
import { getBaseURL } from "../Common/ServerURLs/getBaseURL";
import { AxiosRequestInterface } from "../FAxios/AxiosRequestInterface";
import { fAxios } from "../FAxios/FAxios";
import { getPath } from "../FAxios/getPath";

const method = ServerMethods.updateSeries;
export const updateOrderSeries = async (
  _authDoc: AuthDoc | null,
  requestDoc: UpdateOrderSeriesRequestDoc,
  serverParamsDoc: ServerParamsDoc,
  ): Promise<FBaseModelDoc[]> => {
    const payload: ServerPayloadDoc = {
      serverParamsDoc,
      appVersion: appVersion(),
      requestDoc: requestDoc as unknown as AnyProperty,
      method,
  }
  const baseURL = getBaseURL(method, serverParamsDoc)
  const path = getPath(method, serverParamsDoc);
  if (baseURL) {
      const request: AxiosRequestInterface = {
          method: AxiosMethodTypes.post,
          path: `${path}`,
          baseURL: baseURL,
          postPayload: {payload},
      }
      console.log(`In ${method} Request is :`, request)
      return fAxios<FBaseModelDoc[]>(request)
  }
  const message = {
      message: `Failed to get baseURL`,
      serverParamsDoc: serverParamsDoc || {},
  }
  throw new FError({
      message,
      method: "FBActions.updateModel",
      code: ServerErrorCodes.InvalidData,
  });
};
