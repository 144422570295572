import {FDescriptorDoc} from "../Interfaces/BaseDocs/FDescriptorDoc";
import {isAnyProperty} from "./isAnyProperty";
import {isString} from "./isString";

export const isFDescriptorDoc =
    (candidate: unknown): candidate is FDescriptorDoc => {
      if (isAnyProperty(candidate)) {
        const {fuid, modelType, objectId, modelPath} = candidate;
        return isString(fuid) && isString(objectId) &&
            isString(modelType) && Array.isArray(modelPath);
      }
      return false;
    };
