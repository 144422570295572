/* eslint-disable no-invalid-this */
import {dbActions} from "../../Functions/dbActions";
import {defaultNameDoc} from "../../Functions/defaultNameDoc";
import {AuthDoc} from "../../Interfaces/AuthDoc";
import {FBaseModelDoc} from "../../Interfaces/BaseDocs/FBaseModelDoc";
import {FDescriptorDoc} from "../../Interfaces/BaseDocs/FDescriptorDoc";
import {FNameDoc} from "../../Interfaces/Models/FNameDoc";
import {FOwnerInterface} from "../../Interfaces/Models/FOwnerInterface";
import {ModelFields} from "../../Interfaces/Models/ModelFields";
import {ModelTypes} from "../../Interfaces/Models/ModelTypes";
import {AddOrRemove} from "../../Interfaces/ServerDocs/AddOrRemove";
import {BusinessPersonAtDomainRequestDoc}
  from "../../Interfaces/ServerDocs/Business/BusinessPersonAtDomainRequestDoc";
import {businessPersonRequestDoc}
  from "../../Interfaces/ServerDocs/Business/businessPersonLookupRequestDoc";
import {LookupUsersBusinessByRoleRequestDoc}
  // eslint-disable-next-line max-len
  from "../../Interfaces/ServerDocs/Business/LookupUsersBusinessesByRoleRequestDoc";
import {LookupUsersBusinessByRoleForABusinessRequestDoc}
  // eslint-disable-next-line max-len
  from "../../Interfaces/ServerDocs/Business/LookupUsersBusinessRoleForABusiness";
import {ServerParamsDoc} from "../../Interfaces/ServerParamsDoc";
import {isAnyProperty} from "../../is/isAnyProperty";
import FBaseModel from "./FBaseModel";

const modelType = ModelTypes.Owner;
/* eslint-disable require-jsdoc */
class FOwner extends FBaseModel implements FOwnerInterface {
    static collectionName: ModelTypes = modelType
    constructor(doc?: FBaseModelDoc) {
      super(doc);
      if (doc === undefined || doc === null) {
        this.modelType = modelType;
        this.modelPath = [modelType];
        this.nameDoc = defaultNameDoc();
      }
    }
    get business(): FDescriptorDoc | null {
      return this.getDescriptorDoc(ModelFields.business);
    }
    set business(descriptor: FDescriptorDoc | null) {
      this.setDescriptorDoc(ModelFields.business, descriptor);
    }
    get firstName(): string | null {
      return this.nameDoc.firstName || null;
    }
    set firstName(name: string | null) {
      const doc = this.nameDoc;
      doc.firstName = name;
      this.nameDoc = {...doc};
    }
    get middleName(): string | null {
      return this.nameDoc.middleName || null;
    }
    set middleName(name: string | null) {
      const doc = this.nameDoc;
      doc.middleName = name;
      this.nameDoc = {...doc};
    }
    get lastName(): string | null {
      return this.nameDoc.lastName || null;
    }
    set lastName(name: string | null) {
      const doc = this.nameDoc;
      doc.lastName = name;
      doc.lastNameLC = name?.toLocaleLowerCase() || null;
      this.nameDoc = {...doc};
    }
    get lastNameLC(): string | null {
      return this.nameDoc.lastNameLC || null;
    }
    get fullName(): string {
      const last = this.lastName || "";
      const first = this.firstName || "";
      if (first.length > 0) {
        if (last.length > 0) {
          return `${first} ${last}`;
        }
        return first;
      }
      return last;
    }
    get nameDoc(): FNameDoc {
      const candidate = this.getObjectValue(ModelFields.nameDoc);
      if (isAnyProperty(candidate)) {
        return candidate as FNameDoc;
      }
      const doc: FNameDoc = {};
      this.nameDoc = doc;
      return doc;
    }
    set nameDoc(doc: FNameDoc) {
      this.setObjectValue(ModelFields.nameDoc, doc);
    }
    static getOrCreateBusinessPerson(
        authDoc: AuthDoc | null,
        businessDescriptorDoc: FDescriptorDoc,
        ownerProfileDoc: FDescriptorDoc | null,
        serverParamsDoc: ServerParamsDoc,
        visibleOnly?: boolean
    ): Promise<FBaseModelDoc[]> {
      let requestDoc: businessPersonRequestDoc = {
        businessDescriptorDoc,
        modelType: this.collectionName,
        ownerProfileDoc,
        addOrRemove: AddOrRemove.add,
      };
      if (visibleOnly !== undefined) {
        requestDoc = {...requestDoc, visibleOnly: !!visibleOnly};
      }
      return dbActions.addOrRemoveBusinessPerson(
          authDoc,
          requestDoc,
          serverParamsDoc,
      );
    }
    static removeBusinessPerson(
        authDoc: AuthDoc | null,
        businessDescriptorDoc: FDescriptorDoc,
        ownerProfileDoc: FDescriptorDoc | null,
        serverParamsDoc: ServerParamsDoc,
        visibleOnly?: boolean
    ): Promise<FBaseModelDoc[]> {
      let requestDoc: businessPersonRequestDoc = {
        businessDescriptorDoc,
        modelType: this.collectionName,
        ownerProfileDoc,
        addOrRemove: AddOrRemove.remove,
      };
      if (visibleOnly !== undefined) {
        requestDoc = {...requestDoc, visibleOnly: !!visibleOnly};
      }
      return dbActions.addOrRemoveBusinessPerson(
          authDoc,
          requestDoc,
          serverParamsDoc,
      );
    }
    static async lookupUsersBusinessesByRole(
        authDoc: AuthDoc | null,
        ownerProfileDoc: FDescriptorDoc | null,
        serverParamsDoc: ServerParamsDoc
    ): Promise<FBaseModelDoc[]> {
      const requestDoc: LookupUsersBusinessByRoleRequestDoc = {
        modelType: this.synthesizeSubDescriptorDoc().modelType,
        ownerProfileDoc,
      };
      return dbActions
          .lookupUsersBusinessesByRole(authDoc, requestDoc, serverParamsDoc);
    }
    static async getUsersBusinessesByRole(
        authDoc: AuthDoc | null,
        serverParamsDoc: ServerParamsDoc
    ): Promise<FBaseModelDoc[]> {
      const requestDoc: LookupUsersBusinessByRoleRequestDoc = {
        modelType: this.synthesizeSubDescriptorDoc().modelType,
        ownerProfileDoc: null,
      };
      return dbActions
          .getUsersBusinessesByRole(authDoc, requestDoc, serverParamsDoc);
    }
    static async lookupUsersBusinessByRoleForABusiness(
        authDoc: AuthDoc | null,
        owner: FDescriptorDoc | null,
        business: FDescriptorDoc,
        serverParamsDoc: ServerParamsDoc
    ): Promise<FBaseModelDoc[]> {
      const requestDoc: LookupUsersBusinessByRoleForABusinessRequestDoc = {
        modelType: this.collectionName,
        owner,
        business,
      };
      return dbActions
          .lookupUsersBusinessByRoleForABusiness(
              authDoc, requestDoc, serverParamsDoc);
    }
    static async lookupBusinessPersonAtDomain(
        authDoc: AuthDoc | null,
        businessDomain: string,
        serverParamsDoc: ServerParamsDoc): Promise<FBaseModelDoc[]> {
      const requestDoc: BusinessPersonAtDomainRequestDoc = {
        businessDomain, businessPerson: ModelTypes.Owner};
      return dbActions.
          lookupBusinessPersonAtDomain(authDoc, requestDoc, serverParamsDoc);
    }
}

export default FOwner;
