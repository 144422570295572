import firestore, { GeoPoint, OrderByDirection, Timestamp, WhereFilterOp } from 'firebase/firestore'
import  { User } from 'firebase/auth';
export class FBTimestamp extends Timestamp {
    fake?: string
}
export type FBAuthUser = User;
export type FBQueryDocumentSnapshot = firestore.QueryDocumentSnapshot<firestore.DocumentData>
export type FBDocumentChange = firestore.DocumentChange
export type FBDocumentSnapshot = firestore.DocumentSnapshot<firestore.DocumentData>
export type FBCollectionReference = firestore.CollectionReference<firestore.DocumentData>
export type FBQuery = firestore.Query<firestore.DocumentData>
export type FBWhereFilterOp = WhereFilterOp
export type FBOrderByDirection = OrderByDirection
export type FBQuerySnapshot = firestore.QuerySnapshot<firestore.DocumentData>
export type FBDocumentReference = firestore.DocumentReference<firestore.DocumentData>
export class FSGeoPoint extends GeoPoint {
    fake?: string
}
