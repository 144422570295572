import {fbActions} from "../../FBActions/fbActions";
import {FBQueryDocumentSnapshot} from "../../Mapping/firebaseMapping";
import { AnyProperty } from "../Interfaces/AnyProperty";
import {AuthDoc} from "../Interfaces/AuthDoc";
import {FBaseModelDoc} from "../Interfaces/BaseDocs/FBaseModelDoc";
import { FDescriptorDoc } from "../Interfaces/BaseDocs/FDescriptorDoc";
import {GetOrCreateAlbumRequestDoc}
  from "../Interfaces/ServerDocs/Album/GetOrCreateAlbumRequestDoc";
import {emailLookupRequestDoc}
  from "../Interfaces/ServerDocs/Auth/emailLookupRequestDoc";
import {emailLookupResponseDoc}
  from "../Interfaces/ServerDocs/Auth/emailLookupResponseDoc";
import {LookupUserProfileViaUIDRequestDoc}
  from "../Interfaces/ServerDocs/Auth/LookupUserProfileViaUIDRequestDoc";
import {registerUserRequestDoc}
  from "../Interfaces/ServerDocs/Auth/registerUserRequestDoc";
import {registerUserResponseDoc}
  from "../Interfaces/ServerDocs/Auth/registerUserResponseDoc";
import {BusinessPersonAtDomainRequestDoc}
  from "../Interfaces/ServerDocs/Business/BusinessPersonAtDomainRequestDoc";
import {businessPersonRequestDoc}
  from "../Interfaces/ServerDocs/Business/businessPersonLookupRequestDoc";
import {LookupUsersBusinessByRoleRequestDoc}
  // eslint-disable-next-line max-len
  from "../Interfaces/ServerDocs/Business/LookupUsersBusinessesByRoleRequestDoc";
import {LookupUsersBusinessByRoleForABusinessRequestDoc}
  from "../Interfaces/ServerDocs/Business/LookupUsersBusinessRoleForABusiness";
import {publishOrUnpublishBusinessRequestDoc}
  from "../Interfaces/ServerDocs/Business/publishOrUnpublishBusinessRequestDoc";
import {CreateDocumentRequestDoc}
  from "../Interfaces/ServerDocs/CreateDocumentRequestDoc";
import {UpdateBusinessDomainRequestDoc}
  // eslint-disable-next-line max-len
  from "../Interfaces/ServerDocs/General/BusinessDomain/UpdateBusinessDomainRequestDoc";
import {LookupDisplayNameRequestDoc}
  // eslint-disable-next-line max-len
  from "../Interfaces/ServerDocs/General/DisplayName/LookupDisplayNameRequestDoc";
import {UpdateDisplayNameRequestDoc}
  // eslint-disable-next-line max-len
  from "../Interfaces/ServerDocs/General/DisplayName/UpdateDisplayNameRequestDoc";
import {GetOrCreateFollowRequestDoc}
  from "../Interfaces/ServerDocs/General/Follow/GetOrCreateFollowRequestDoc";
import {UnblockPhoneNumberRequestDoc}
  // eslint-disable-next-line max-len
  from "../Interfaces/ServerDocs/General/PhoneNumber/UnblockPhoneNumberRequestDoc";
import {GetOrCreateBusinessRoomRequestDoc}
  // eslint-disable-next-line max-len
  from "../Interfaces/ServerDocs/General/Room/GetOrCreateBusinessRoomRequestDoc";
import {GetOrCreatePrivateRoomRequestDoc}
  from "../Interfaces/ServerDocs/General/Room/GetOrCreatePrivateRoomRequestDoc";
import {SendMessageRequestDoc}
  from "../Interfaces/ServerDocs/General/SendMessageRequestDoc";
import {UpdateLocationRequestDoc}
  from "../Interfaces/ServerDocs/General/UpdateLocationRequestDoc";
import {GetDocumentRequestDoc}
  from "../Interfaces/ServerDocs/GetDocumentRequestDoc";
import {ListQueryRequestDoc}
  from "../Interfaces/ServerDocs/ListQueryRequestDoc";
import {LookupSearchCenterRequestDoc}
  from "../Interfaces/ServerDocs/Location/LookupSearchCenterRequestDoc";
import {UpdateLocationNameRequestDoc}
  from "../Interfaces/ServerDocs/Location/UpdateLocationNameRequestDoc";
import {lookupBusinessByDomainRequestDoc}
  from "../Interfaces/ServerDocs/lookupBusinessByDomainRequestDoc";
import {CreateNewOrderFromExistingOrderRequestDoc}
  // eslint-disable-next-line max-len
  from "../Interfaces/ServerDocs/Order/CreateNewOrderFromExistingOrderRequestDoc";
import {GetOrCreateUnsubmittedOrderRequestDoc}
  from "../Interfaces/ServerDocs/Order/GetOrCreateUnsubmittedOrderRequestDoc";
import { UpdateOrderSeriesRequestDoc }
  from "../Interfaces/ServerDocs/Order/UpdateOrderSeriesRequestDoc";
import {CreatePhoneNumberRequestDoc}
  from "../Interfaces/ServerDocs/PhoneNumber/CreatePhoneNumberRequestDoc";
import {ListPhoneNumbersRequestDoc}
  from "../Interfaces/ServerDocs/PhoneNumber/ListPhoneNumbersRequestDoc";
import {UpdatePhoneNumberRequestDoc}
  from "../Interfaces/ServerDocs/PhoneNumber/UpdatePhoneNumberRequestDoc";
import {UpdatePhoneNumberTextEnabledRequestDoc}
  // eslint-disable-next-line max-len
  from "../Interfaces/ServerDocs/PhoneNumber/UpdatePhoneNumberTextEnabledRequestDoc";
import {ChangeListOrderRequestDoc}
  from "../Interfaces/ServerDocs/Product/ChangeListOrderRequestDoc";
import {GetOrCreateProductByBusinessRequestDoc}
  // eslint-disable-next-line max-len
  from "../Interfaces/ServerDocs/Product/GetOrCreateProductByBusinessRequestDoc";
import {PublishProductRequestDoc}
  from "../Interfaces/ServerDocs/Product/PublishProductRequestDoc";
import {GetOrCreateRatingRequestDoc}
  from "../Interfaces/ServerDocs/Rating/GetOrCreateRatingRequestDoc";
import {ListRatingsRequestDoc}
  from "../Interfaces/ServerDocs/Rating/ListRatingsRequestDoc";
import {RateRequestDoc} from "../Interfaces/ServerDocs/Rating/RateRequestDoc";
import {RemoveDocRequestDoc}
  from "../Interfaces/ServerDocs/RemoveDocRequestDoc";
import { CreateStripeBusinessAccountRequestDoc } from "../Interfaces/ServerDocs/Stripe/CreateStripeBusinessAccountRequestDoc";
import { UpdateInvoiceTipRequestDoc } from "../Interfaces/ServerDocs/Stripe/UpdateInvoiceTipRequestDoc";
import { UpdateOrderInvoiceRequestDoc } from "../Interfaces/ServerDocs/Stripe/UpdateOrderInvoiceRequestDoc";
import {UpdateDocumentRequestDoc}
  from "../Interfaces/ServerDocs/UpdateDocumentRequestDoc";
import {ServerParamsDoc} from "../Interfaces/ServerParamsDoc";

export const dbActions = {
  createStripePaymentIntent: async (
    authDoc: AuthDoc | null,
    order: FDescriptorDoc,
    customer: FDescriptorDoc,
    serverParamsDoc: ServerParamsDoc,
  ): Promise<AnyProperty[]> => {
      return fbActions.createStripePaymentIntent(authDoc, {order, customer}, serverParamsDoc)
  },
  createStripeBusinessAccount: async (
    authDoc: AuthDoc | null,
    requestDoc: CreateStripeBusinessAccountRequestDoc,
    serverParamsDoc: ServerParamsDoc
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.createStripeBusinessAccount(authDoc, requestDoc, serverParamsDoc);
  },
  updateStripePaymentIntent: async (
    authDoc: AuthDoc | null,
    order: FDescriptorDoc,
    customer: FDescriptorDoc,
    paymentIntent: AnyProperty,
    serverParamsDoc: ServerParamsDoc,
  ): Promise<AnyProperty[]> => {
      return fbActions.updateStripePaymentIntent(authDoc, {order, customer, paymentIntent}, serverParamsDoc)
  },
  getOrCreateFollow: async (
      authDoc: AuthDoc | null,
      requestDoc: GetOrCreateFollowRequestDoc,
      serverParamsDoc: ServerParamsDoc): Promise<FBaseModelDoc[]> => {
    return fbActions.getOrCreateFollow(authDoc, requestDoc, serverParamsDoc);
  },
  lookupUserProfileViaUID: async (
      authDoc: AuthDoc | null,
      requestDoc: LookupUserProfileViaUIDRequestDoc,
      serverParamsDoc: ServerParamsDoc): Promise<FBaseModelDoc[]> => {
    return fbActions
        .lookupUserProfileViaUID(authDoc, requestDoc, serverParamsDoc);
  },
  getOrCreateBusinessRoom: async (
      authDoc: AuthDoc | null,
      requestDoc: GetOrCreateBusinessRoomRequestDoc,
      serverParamsDoc: ServerParamsDoc): Promise<FBaseModelDoc[]> => {
    return fbActions
        .getOrCreateBusinessRoom(authDoc, requestDoc, serverParamsDoc);
  },
  getOrCreatePrivateRoom: async (
      authDoc: AuthDoc | null,
      requestDoc: GetOrCreatePrivateRoomRequestDoc,
      serverParamsDoc: ServerParamsDoc): Promise<FBaseModelDoc[]> => {
    return fbActions
        .getOrCreatePrivateRoom(authDoc, requestDoc, serverParamsDoc);
  },
  getOrCreateUnsubmittedOrder: async (
      authDoc: AuthDoc | null,
      requestDoc: GetOrCreateUnsubmittedOrderRequestDoc,
      serverParamsDoc: ServerParamsDoc
  ): Promise<FBaseModelDoc[]> => {
    return fbActions
        .getOrCreateUnsubmittedOrder(
            authDoc,
            requestDoc,
            serverParamsDoc
        );
  },
  getListOfSnapshots: async (
      authDoc: AuthDoc | null,
      requestDoc: ListQueryRequestDoc,
      serverParams: ServerParamsDoc): Promise<FBQueryDocumentSnapshot[]> => {
    return fbActions.getListOfSnapshots(authDoc, requestDoc, serverParams);
  },
  getList: async (
      authDoc: AuthDoc | null,
      requestDoc: ListQueryRequestDoc,
      serverParams: ServerParamsDoc): Promise<FBaseModelDoc[]> => {
    return fbActions.getList(authDoc, requestDoc, serverParams);
  },
  getDocument: async (
      authDoc: AuthDoc | null,
      requestDoc: GetDocumentRequestDoc,
      serverParams: ServerParamsDoc): Promise<FBaseModelDoc[]> => {
    return fbActions.getDocument(authDoc, requestDoc, serverParams);
  },
  createDocument: async (
      authDoc: AuthDoc | null,
      requestDoc: CreateDocumentRequestDoc,
      serverParamsDoc: ServerParamsDoc,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.createDocument(
        authDoc,
        requestDoc,
        serverParamsDoc,
    );
  },
  updateDocument: async (
      authDoc: AuthDoc | null,
      requestDoc: UpdateDocumentRequestDoc,
      serverParams: ServerParamsDoc
  ): Promise<FBaseModelDoc[]> => {
    console.log("IN dbActions.updateDOcument");
    return fbActions.updateDocument(
        authDoc, requestDoc, serverParams);
  },
  removeDocument: async (
      authDoc: AuthDoc | null,
      requestDoc: RemoveDocRequestDoc,
      serverParams: ServerParamsDoc
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.removeDocument(
        authDoc,
        requestDoc,
        serverParams);
  },
  lookupEmail: async (
      authDoc: AuthDoc | null,
      requestDoc: emailLookupRequestDoc,
      serverParamsDoc: ServerParamsDoc): Promise<emailLookupResponseDoc[]> => {
    return fbActions.lookupEmail(authDoc, requestDoc, serverParamsDoc);
  },
  registerUser: async (
      authDoc: AuthDoc | null,
      requestDoc: registerUserRequestDoc,
      serverParamsDoc: ServerParamsDoc): Promise<registerUserResponseDoc[]> => {
    return fbActions.registerUser(authDoc, requestDoc, serverParamsDoc);
  },
  lookupBusinessByDomain: async (
      authDoc: AuthDoc | null,
      requestDoc: lookupBusinessByDomainRequestDoc,
      serverParamsDoc: ServerParamsDoc): Promise<FBaseModelDoc[]> => {
    return fbActions.lookupBusinessByDomain(
        authDoc,
        requestDoc,
        serverParamsDoc);
  },
  updateBusinessDomain: async (
      authDoc: AuthDoc | null,
      requestDoc: UpdateBusinessDomainRequestDoc,
      serverParamsDoc: ServerParamsDoc): Promise<FBaseModelDoc[]> => {
    return fbActions.updateBusinessDomain(
        authDoc,
        requestDoc,
        serverParamsDoc);
  },
  lookupDisplayName: async (
      authDoc: AuthDoc | null,
      requestDoc: LookupDisplayNameRequestDoc,
      serverParamsDoc: ServerParamsDoc): Promise<FBaseModelDoc[]> => {
    return fbActions.lookupDisplayName(
        authDoc,
        requestDoc,
        serverParamsDoc);
  },
  updateDisplayName: async (
      authDoc: AuthDoc | null,
      requestDoc: UpdateDisplayNameRequestDoc,
      serverParamsDoc: ServerParamsDoc): Promise<FBaseModelDoc[]> => {
    return fbActions.updateDisplayName(
        authDoc,
        requestDoc,
        serverParamsDoc);
  },
  sendMessage: async (
      authDoc: AuthDoc | null,
      requestDoc: SendMessageRequestDoc,
      serverParamsDoc: ServerParamsDoc): Promise<FBaseModelDoc[]> => {
    return fbActions.sendMessage(
        authDoc,
        requestDoc,
        serverParamsDoc);
  },
  addOrRemoveBusinessPerson: async (
      authDoc: AuthDoc | null,
      requestDoc: businessPersonRequestDoc,
      serverParamsDoc: ServerParamsDoc,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions
        .addOrRemoveBusinessPerson(
            authDoc, requestDoc, serverParamsDoc);
  },
  getOrCreateMyProfile: async (
      authDoc: AuthDoc | null,
      serverParams: ServerParamsDoc): Promise<FBaseModelDoc[]> => {
    return fbActions.getOrCreateMyProfile(authDoc, serverParams);
  },
  updateModel: async (
      authDoc: AuthDoc | null,
      requestDoc: UpdateDocumentRequestDoc,
      serverParamsDoc: ServerParamsDoc,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.updateModel(authDoc, requestDoc, serverParamsDoc);
  },
  updateLocation: async (
      authDoc: AuthDoc | null,
      requestDoc: UpdateLocationRequestDoc,
      serverParamsDoc: ServerParamsDoc,
  ): Promise<FBaseModelDoc[]> =>{
    return fbActions.updateLocation(authDoc, requestDoc, serverParamsDoc);
  },
  unblockPhoneNumber: async (
      authDoc: AuthDoc | null,
      requestDoc: UnblockPhoneNumberRequestDoc,
      serverParamsDoc: ServerParamsDoc,
  ): Promise<FBaseModelDoc[]> =>{
    console.log(authDoc, requestDoc, serverParamsDoc);
    console.log("In unblockPhoneNumber. Neil need to fix");
    return Promise.resolve([]);
  },
  lookupUsersBusinessesByRole: async (
      authDoc: AuthDoc | null,
      requestDoc: LookupUsersBusinessByRoleRequestDoc,
      serverParamsDoc: ServerParamsDoc,
  ): Promise<FBaseModelDoc[]> =>{
    return fbActions
        .lookupUsersBusinessesByRole(authDoc, requestDoc, serverParamsDoc);
  },
  getUsersBusinessesByRole: async (
    authDoc: AuthDoc | null,
    requestDoc: LookupUsersBusinessByRoleRequestDoc,
    serverParamsDoc: ServerParamsDoc,
  ): Promise<FBaseModelDoc[]> =>{
    return fbActions
        .getUsersBusinessesByRole(authDoc, requestDoc, serverParamsDoc);
  },
  lookupSearchCenter: async (
      authDoc: AuthDoc | null,
      requestDoc: LookupSearchCenterRequestDoc,
      serverParamsDoc: ServerParamsDoc,
  ): Promise<FBaseModelDoc[]> =>{
    return fbActions
        .lookupSearchCenter(authDoc, requestDoc, serverParamsDoc);
  },
  updateLocationName: async (
      authDoc: AuthDoc | null,
      requestDoc: UpdateLocationNameRequestDoc,
      serverParamsDoc: ServerParamsDoc,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions
        .updateLocationName(authDoc, requestDoc, serverParamsDoc);
  },
  createPhoneNumber: async (
      authDoc: AuthDoc | null,
      requestDoc: CreatePhoneNumberRequestDoc,
      serverParamsDoc: ServerParamsDoc,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions
        .createPhoneNumber(authDoc, requestDoc, serverParamsDoc);
  },
  updatePhoneNumber: async (
      authDoc: AuthDoc | null,
      requestDoc: UpdatePhoneNumberRequestDoc,
      serverParamsDoc: ServerParamsDoc,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions
        .updatePhoneNumber(authDoc, requestDoc, serverParamsDoc);
  },
  updatePhoneNumberTextEnabled: async (
      authDoc: AuthDoc | null,
      requestDoc: UpdatePhoneNumberTextEnabledRequestDoc,
      serverParamsDoc: ServerParamsDoc
  ): Promise<FBaseModelDoc[]> => {
    return fbActions
        .updatePhoneNumberTextEnabled(authDoc, requestDoc, serverParamsDoc);
  },
  getOrCreateProductByBusiness: async (
    authDoc: AuthDoc | null,
    requestDoc: GetOrCreateProductByBusinessRequestDoc,
    serverParamsDoc: ServerParamsDoc
  ): Promise<FBaseModelDoc[]> => {
    return fbActions
        .getOrCreateProductByBusiness(authDoc, requestDoc, serverParamsDoc);
  },
  lookupUsersBusinessByRoleForABusiness: async (
      authDoc: AuthDoc | null,
      requestDoc: LookupUsersBusinessByRoleForABusinessRequestDoc,
      serverParamsDoc: ServerParamsDoc
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.lookupUsersBusinessByRoleForABusiness(
        authDoc, requestDoc, serverParamsDoc);
  },
  publishProduct: async (
      authDoc: AuthDoc | null,
      requestDoc: PublishProductRequestDoc,
      serverParamsDoc: ServerParamsDoc
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.publishProduct(
        authDoc, requestDoc, serverParamsDoc);
  },
  getOrCreateNewBusiness: async (
    authDoc: AuthDoc | null,
    serverParamsDoc: ServerParamsDoc
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.getOrCreateNewBusiness(
        authDoc, serverParamsDoc);
  },
  publishOrUnpublishBusiness: async (
    authDoc: AuthDoc | null,
    requestDoc: publishOrUnpublishBusinessRequestDoc,
    serverParamsDoc: ServerParamsDoc): Promise<FBaseModelDoc[]> => {
    return fbActions.publishOrUnpublishBusiness(
        authDoc, requestDoc, serverParamsDoc);
  },
  createNewOrderFromExistingOrder: async (
      authDoc: AuthDoc | null,
      requestDoc: CreateNewOrderFromExistingOrderRequestDoc,
      serverParamsDoc: ServerParamsDoc): Promise<FBaseModelDoc[]> => {
    return fbActions.createNewOrderFromExistingOrder(
        authDoc, requestDoc, serverParamsDoc);
  },
  lookupBusinessPersonAtDomain: async (
      authDoc: AuthDoc | null,
      requestDoc: BusinessPersonAtDomainRequestDoc,
      serverParamsDoc: ServerParamsDoc): Promise<FBaseModelDoc[]> => {
    return fbActions.lookupBusinessPersonAtDomain(
        authDoc, requestDoc, serverParamsDoc);
  },
  getListOfPhoneNumbers: async (
      authDoc: AuthDoc | null,
      requestDoc: ListPhoneNumbersRequestDoc,
      serverParamsDoc: ServerParamsDoc): Promise<FBaseModelDoc[]> => {
    return fbActions
        .getListOfPhoneNumbers(authDoc, requestDoc, serverParamsDoc);
  },
  changeListOrder: async (
    authDoc: AuthDoc | null,
    requestDoc: ChangeListOrderRequestDoc,
    serverParamsDoc: ServerParamsDoc): Promise<FBaseModelDoc[]> => {
  return fbActions
      .changeListOrder(authDoc, requestDoc, serverParamsDoc);
  },
  rate: async (
      authDoc: AuthDoc | null,
      requestDoc: RateRequestDoc,
      serverParamsDoc: ServerParamsDoc): Promise<FBaseModelDoc[]> => {
    return fbActions
        .rate(authDoc, requestDoc, serverParamsDoc);
  },
  getOrCreateRating: async (
      authDoc: AuthDoc | null,
      requestDoc: GetOrCreateRatingRequestDoc,
      serverParamsDoc: ServerParamsDoc): Promise<FBaseModelDoc[]> => {
    return fbActions
        .getOrCreateRating(authDoc, requestDoc, serverParamsDoc);
  },
    listRatings: async (
    authDoc: AuthDoc | null,
    requestDoc: ListRatingsRequestDoc,
    serverParamsDoc: ServerParamsDoc): Promise<FBaseModelDoc[]> => {
    return fbActions
        .listRatings(authDoc, requestDoc, serverParamsDoc);
  },
  getOrCreateAlbum: async (
    authDoc: AuthDoc | null,
    requestDoc: GetOrCreateAlbumRequestDoc,
    serverParamsDoc: ServerParamsDoc): Promise<FBaseModelDoc[]> => {
    return fbActions
        .getOrCreateAlbum(authDoc, requestDoc, serverParamsDoc);
  },
  updateOrderSeries: async (
    authDoc: AuthDoc | null,
    requestDoc: UpdateOrderSeriesRequestDoc,
    serverParamsDoc: ServerParamsDoc): Promise<FBaseModelDoc[]> => {
    return fbActions
        .updateOrderSeries(authDoc, requestDoc, serverParamsDoc);
  },
  updateOrderInvoice: async (
    authDoc: AuthDoc | null,
    requestDoc: UpdateOrderInvoiceRequestDoc,
    serverParamsDoc: ServerParamsDoc): Promise<FBaseModelDoc[]> => {
    return fbActions
        .updateOrderInvoice(authDoc, requestDoc, serverParamsDoc);
  },
  updateInvoiceTip: async (
    authDoc: AuthDoc | null,
    requestDoc: UpdateInvoiceTipRequestDoc,
    serverParamsDoc: ServerParamsDoc): Promise<FBaseModelDoc[]> => {
    return fbActions
        .updateInvoiceTip(authDoc, requestDoc, serverParamsDoc);
  },
};
