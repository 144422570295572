import FError from "../Common/ErrorHandling/FError";
import {AuthDoc} from "../Common/Interfaces/AuthDoc";
import {FBaseModelDoc} from "../Common/Interfaces/BaseDocs/FBaseModelDoc";
import {ServerErrorCodes}
  from "../Common/Interfaces/ServerDocs/ServerErrorCodes";
import {ServerParamsDoc} from "../Common/Interfaces/ServerParamsDoc";
import { ServerMethods } from "../Common/ServerMethods";
import { ServerPayloadDoc } from "../Common/Interfaces/ServerPayloadDoc";
import { AnyProperty } from "../Common/Interfaces/AnyProperty";
import { appVersion } from "../Common/Functions/appVersion";
import { getBaseURL } from "../Common/ServerURLs/getBaseURL";
import { getPath } from "../FAxios/getPath";
import { AxiosRequestInterface } from "../FAxios/AxiosRequestInterface";
import { AxiosMethodTypes } from "../Common/ServerMethodsObj/AxiosMethodTypes";
import { fAxios } from "../FAxios/FAxios";
import { UpdateOrderInvoiceRequestDoc } from "../Common/Interfaces/ServerDocs/Stripe/UpdateOrderInvoiceRequestDoc";

const method = ServerMethods.updateOrderInvoice;
export const updateOrderInvoice = (
    _authDoc: AuthDoc | null,
    requestDoc: UpdateOrderInvoiceRequestDoc,
    serverParamsDoc: ServerParamsDoc
): Promise<FBaseModelDoc[]> => {
    const payload: ServerPayloadDoc = {
        serverParamsDoc,
        appVersion: appVersion(),
        requestDoc: requestDoc as unknown as AnyProperty,
        method,
    }
    const baseURL = getBaseURL(method, serverParamsDoc)
    const path = getPath(method, serverParamsDoc);
    if (baseURL && path) {
        const request: AxiosRequestInterface = {
            method: AxiosMethodTypes.post,
            path,
            baseURL: baseURL,
            postPayload: {payload},
        }
        return fAxios<FBaseModelDoc[]>(request)
    }
    const message = {
      message: `Failed to get baseURL`,
      serverParamsDoc: serverParamsDoc || {},
    }
      throw new FError({
        message,
        method,
        code: ServerErrorCodes.InvalidData,
      });
    };

/*


const updateInvoiceTip = async (orderDoc: FDescriptorDoc, tip: number, trace: string): Promise<FBaseModelDoc[]> => {
    if (client) {
        const key = 'updateInvoiceTip'
        const methodName = getMethodName(key)
        if (methodName) {
            return handleFirebaseMethod<FBaseModelDoc>(methodName, {
                doc: { orderDoc: FOrder.synthesizeDescriptor(orderDoc.fuid), tip },
                trace,
                params: {},
            })
        } else {
            throw new FAppError(`In fbActions.updateInvoiceTip failed to get methodName for ${key}`)
        }
    } else {
        console.log(`In fbAction.updateInvoiceTip. This should not be used on the server`)
        return Promise.resolve([])
    }
}
*/