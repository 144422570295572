import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { isAnyProperty } from '../Common/is/isAnyProperty';
import { isString } from '../Common/is/isString';
import { baseURL } from '../config/initialURL';
import { getIdToken } from '../firebase/authentication';
import { AxiosRequestInterface } from './AxiosRequestInterface';

export const fAxios = async <T>(request: AxiosRequestInterface): Promise<T> => {
    try {
        const token = await getIdToken()
        const config: AxiosRequestConfig = {
            method: request.method,
            url: request.path,
            baseURL: request.baseURL || baseURL,
            headers: { Authorization: `Bearer ${token || ''}`},
            responseType: 'json',
            data: request.postPayload || undefined,
            params: request.queryParams || undefined,
            onUploadProgress: request.onUploadProgress || undefined,
            cancelToken: request.cancelTokenSource ? request.cancelTokenSource.token : undefined,  
        }
        // console.log('About to do axios. Config is:', config)
        const response: AxiosResponse<T> = await axios(config)
        return response.data;
    } catch (error) {
        if (error.response) {
            if (error.response.data) {
                const {data} = error.response;
                // console.log('Axios error.response Data is: ', data);
                if (isAnyProperty(data) || Array.isArray(data)) {
                    throw new Error(JSON.stringify(data))
                } else if (isString(data)) {
                    throw new Error(data)
                } else {
                    console.log('Unknown data type')
                    throw error;
                }
            } else {
                console.log('No data for error.resopnse')
                throw error;
            }
        } else if (error.request) {
            console.log('Request error: ', error.request);
            throw error
        }
        console.log('Unknown error type', error)
        throw error;
    }
}