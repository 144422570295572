import FError from "../Common/ErrorHandling/FError"
import { appVersion } from "../Common/Functions/appVersion"
import { AnyProperty } from "../Common/Interfaces/AnyProperty"
import { AuthDoc } from "../Common/Interfaces/AuthDoc"
import { FBaseModelDoc } from "../Common/Interfaces/BaseDocs/FBaseModelDoc"
import { ServerErrorCodes } from "../Common/Interfaces/ServerDocs/ServerErrorCodes"
import {UpdateDocumentRequestDoc}
    from "../Common/Interfaces/ServerDocs/UpdateDocumentRequestDoc"
import { ServerParamsDoc } from "../Common/Interfaces/ServerParamsDoc"
import { ServerPayloadDoc } from "../Common/Interfaces/ServerPayloadDoc"
import { isString } from "../Common/is/isString"
import { ServerMethods } from "../Common/ServerMethods"
import { getBaseURL } from "../Common/ServerURLs/getBaseURL"
import { getPath } from "../FAxios/getPath"

import { AxiosMethodTypes } from "../Common/ServerMethodsObj/AxiosMethodTypes"
import { AxiosRequestInterface } from "../FAxios/AxiosRequestInterface"
import { fAxios } from "../FAxios/FAxios"

const method = ServerMethods.updateModel;
export const updateModel = async (
    _authDoc: AuthDoc | null,
    requestDoc: UpdateDocumentRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    ): Promise<FBaseModelDoc[]> => {
    const payload: ServerPayloadDoc = {
        serverParamsDoc,
        appVersion: appVersion(),
        requestDoc: requestDoc as unknown as AnyProperty,
        method,
    }
    const baseURL = getBaseURL(method, serverParamsDoc)
    const path = getPath(method, serverParamsDoc);
    if (baseURL && isString(path)) {
        const {descriptorDoc: {fuid, modelType}} = requestDoc;
        const request: AxiosRequestInterface = {
            method: AxiosMethodTypes.put,
            path: `${path}/${modelType}/${fuid}`,
            baseURL: baseURL,
            postPayload: {payload},
        }
        // console.log(`In ${method} REquest is :`, request)
        return fAxios<FBaseModelDoc[]>(request)
    }
    console.log('BaseURL:', baseURL);
    console.log("Path is: ", path)
    const message = {
        message: `Failed to get baseURL`,
        serverParamsDoc: serverParamsDoc || {},
    }
    throw new FError({
        message,
        method: "FBActions.updateModel",
        code: ServerErrorCodes.InvalidData,
    });
}
