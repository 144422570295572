import FBaseModel from "../Common/Classes/Models/FBaseModel"
import FError from "../Common/ErrorHandling/FError"
import { appVersion } from "../Common/Functions/appVersion"
import { AnyProperty } from "../Common/Interfaces/AnyProperty"
import { AuthDoc } from "../Common/Interfaces/AuthDoc"
import { ModelTypes } from "../Common/Interfaces/Models/ModelTypes"
import { initializeRequestDoc } from "../Common/Interfaces/ServerDocs/Admin/initializeRequestDoc"
import { ServerErrorCodes } from "../Common/Interfaces/ServerDocs/ServerErrorCodes"
import { ServerParamsDoc } from "../Common/Interfaces/ServerParamsDoc"
import { ServerPayloadDoc } from "../Common/Interfaces/ServerPayloadDoc"
import { ServerMethods } from "../Common/ServerMethods"
import { getBaseURL } from "../Common/ServerURLs/getBaseURL"
import { getPath } from "../FAxios/getPath"
import { AxiosMethodTypes } from "../Common/ServerMethodsObj/AxiosMethodTypes"
import { AxiosRequestInterface } from "../FAxios/AxiosRequestInterface"
import { fAxios } from "../FAxios/FAxios"

const method = ServerMethods.initializeServer
// auth/email-already-exists
export const initializeServer = async (
    _authDoc: AuthDoc | null,
    requestDoc: initializeRequestDoc,
    serverParamsDoc: ServerParamsDoc): Promise<AnyProperty[]> => {
    const payload: ServerPayloadDoc = {
        serverParamsDoc,
        doc: null,
        modelType: ModelTypes.BaseModel,
        modelPath: FBaseModel.synthesizeSubDescriptorDoc().modelPath,
        updateDoc: null,
        queryDoc: null,
        descriptorDoc: null,
        businessDescriptor: null,
        appVersion: appVersion(),
        requestDoc: requestDoc as unknown as AnyProperty,
        method,
    }
    const baseURL = getBaseURL(method, serverParamsDoc)
    const path = getPath(method, serverParamsDoc);
    if (baseURL && path) {
        const request: AxiosRequestInterface = {
            method: AxiosMethodTypes.post,
            path,
            baseURL: baseURL,
            postPayload: {payload},
        }
        console.log('initialize Request is :', request)
        return fAxios<AnyProperty[]>(request)
    }
    const message = {
        message: `Failed to get baseURL`,
        serverParamsDoc: serverParamsDoc || {},
    }
    throw new FError({
        message,
        method,
        code: ServerErrorCodes.InvalidData,
    });
}
