import {FNameDoc} from "../Interfaces/Models/FNameDoc";

export const defaultNameDoc = (): FNameDoc => {
  return {
    firstName: null,
    middleName: null,
    lastName: null,
    lastNameLC: null,
  };
};
