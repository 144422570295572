import { makeStyles} from '@material-ui/core';

const useStyles = makeStyles(() => ({
    headerStyle: {

    },

    textStyle: {

    },
    olStyle: {
        color: 'blue',
    },
    listStyle: {
        marginLeft: 4,
        marginRight: 20,
        overflow: 'auto',
        height: 'calc(100vh - 70px)',
    },
}));

export default useStyles;