import { createUserWithEmailAndPassword, getAuth, sendPasswordResetEmail, signInWithEmailAndPassword, Unsubscribe, User, UserCredential } from 'firebase/auth';
import FError from '../Common/ErrorHandling/FError';
import { ResponseCode } from '../Common/ErrorHandling/ResponseCode';
import { FBAuthUser } from '../Mapping/firebaseMapping';


export const initializeAuth = (handler: (user: User | null) => void, errorHandler: (error: Error) => void, completedHandler: () => void): Unsubscribe => {
    return getAuth().onAuthStateChanged(handler, errorHandler, completedHandler)
}

export const currentUser = (): FBAuthUser | null => {
    return getAuth().currentUser;
}
export const getCurrentUserUID = (): string | null => {
    const user: FBAuthUser | null = currentUser()
    return user ? user.uid : null
}
export const getIdToken = (): Promise<string | null> => {
    const user: FBAuthUser | null = currentUser()
    if (user) {
        return user.getIdToken()
    }
    return Promise.resolve(null)
}

export const loginViaEmailAndPassword = (email: string, password: string): Promise<UserCredential>  => {
    const auth = getAuth()
    const method = "loginViaEmailAndPassword"
    return signInWithEmailAndPassword(auth, email, password)
        .then((userCredential: UserCredential) => {
            console.log(userCredential)
            return userCredential
        })
        .catch((error) => {
            console.log('FirebaseAuth Error code', error.code)
            console.log('FirebaseAuth Error Message', error.message)
            throw FError.fbErrortoFError(error, method, ResponseCode.InternalServerError)
        })
}

export const passwordReset = (email: string): Promise<string> => {
    const method = "passwordReset"
    const auth = getAuth()
    return new Promise((resolve, reject) => {
        return sendPasswordResetEmail(auth, email, undefined)
            .then(() => {
                resolve(`Password reset email sent to ${email}`)
            })
            .catch((error) => {
                reject(FError.fbErrortoFError(error, method, ResponseCode.InternalServerError))
            })
    })
}

export const logout = (): Promise<void> => {
    const method = "logout";
    const uid = getCurrentUserUID();
    if (uid) {
        return getAuth().signOut().catch((error) => {
            throw FError.fbErrortoFError(error, method, ResponseCode.InternalServerError);
        })
    }
    return Promise.resolve();
}

export const createUserViaEmailAndPassword = (email: string, password: string): Promise<UserCredential> => {
    const auth = getAuth()
    return createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential: UserCredential) => {
            console.log(userCredential)
            return userCredential
        })
        .catch((error) => {
            const method = "createUserViaEmailAndPassword";
            console.log('FirebaseAuth Error code', error.code)
            console.log('FirebaseAuth Error Message', error.message)
            throw FError.fbErrortoFError(error, method, ResponseCode.InternalServerError)
        })
}