export enum ServerErrorCodes {
    Unauthorized = "Unauthorized",
    InvalidPrecondition = "Invalid-Precondition",
    InvalidData = "Invalid-Data",
    MisconfiguedServer = "MisconfiguredServer",
    NoCode = "NoCode",
    InvalidServerCall = "Invalid Server Call",
    FSCancelled = "cancelled",
    FSUnknown = "unknown",
    FSInvalidArgument = "invalid-argument",
    FSDeadline = "deadline-exceeded",
    FSNotFound = "not-found",
    FSAlreadyExists = "already-exists",
    FSPermissionDenied = "permission-denied",
    FSResourceExhausted = "resource-exhausted",
    FSFailedPrecondition = "failed-precondition",
    FSAborted = "aborted",
    FSOutOrRange = "out-of-range",
    FSUnimplemented = "unimplemented",
    FSInternal = "internal",
    FSUnavailable = "unavailable",
    FSDataLoss = "data-loss",
    FSUnauthenitcated = "unauthenticated",
    AlgoliaAPIError = 'AlgoliaAPIError',
}
