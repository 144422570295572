import {AuthDoc} from "../Common/Interfaces/AuthDoc";
import {FBaseModelDoc} from "../Common/Interfaces/BaseDocs/FBaseModelDoc";
import {UpdateLocationRequestDoc}
  from "../Common/Interfaces/ServerDocs/General/UpdateLocationRequestDoc";
import {ServerParamsDoc} from "../Common/Interfaces/ServerParamsDoc";
import { fbClientActions } from "../FBClientActions/fbClientActions";

export const updateLocation = (
    authDoc: AuthDoc | null,
    requestDoc: UpdateLocationRequestDoc,
    serverParamsDoc: ServerParamsDoc): Promise<FBaseModelDoc[]> => {
      return fbClientActions.updateLocation(authDoc, requestDoc, serverParamsDoc);
};