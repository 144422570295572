// export const cloudName = 'rendevu';
export const cloudName = "rendevu";
export const uploadPreset = "kc9tiidv";
export const apiBaseURL = "https://api.cloudinary.com/v1_1/";
export const cloudinaryUploadPath = "/upload";
export const uploadURL = `${apiBaseURL}${cloudName}${cloudinaryUploadPath}`;
export const avatarFolder = "avatar";
export const photoFolder = "photo";
export const baseURL = `http://res.cloudinary.com/${cloudName}`;
export const baseSecureURL = `https://res.cloudinary.com/${cloudName}`;
export const photoURL =
    `${baseSecureURL}/image${cloudinaryUploadPath}/${photoFolder}`;

const cloudinaryConfig = {
  cloudName,
  uploadPreset,
  APIKey: "316136497786786",
  baseURL,
  baseSecureURL: "https://res.cloudinary.com/rendevu",
  apiBaseURL,
  cloudinaryUploadPath,
  uploadURL,
};
export default cloudinaryConfig;

export const defaultPublicId =
    "vhYgE9ZCxfZbB0j2X7uGnAzpNdC3/1568525889485/babe_sc2ubu";
