import FError from "../Common/ErrorHandling/FError";
import { FBaseModelDoc } from "../Common/Interfaces/BaseDocs/FBaseModelDoc";
import { ServerErrorCodes } from "../Common/Interfaces/ServerDocs/ServerErrorCodes";
import { ServerParamsDoc } from "../Common/Interfaces/ServerParamsDoc";
import { ServerPayloadDoc } from "../Common/Interfaces/ServerPayloadDoc";
import { ServerMethods } from "../Common/ServerMethods";
import { getBaseURL } from "../Common/ServerURLs/getBaseURL";
import { getPath } from "../FAxios/getPath";
import { AxiosMethodTypes } from "../Common/ServerMethodsObj/AxiosMethodTypes";
import { AxiosRequestInterface } from "../FAxios/AxiosRequestInterface";
import { fAxios } from "../FAxios/FAxios";

export const finalRequest = async (method: ServerMethods, payload: ServerPayloadDoc, serverParamsDoc: ServerParamsDoc): Promise<FBaseModelDoc[]> => {
    const baseURL = getBaseURL(method, serverParamsDoc)
    const path = getPath(method, serverParamsDoc);
    if (baseURL && path) {
        const request: AxiosRequestInterface = {
            method: AxiosMethodTypes.post,
            path,
            baseURL: baseURL,
            postPayload: {payload},
        }
        // console.log(`${method} Request is :`, request)
        return fAxios<FBaseModelDoc[]>(request)
    }
    const message = {
        message: `Failed to get baseURL`,
        serverParamsDoc: serverParamsDoc || {},
    }
    throw new FError({
        message,
        method: "FBActions.updateModel",
        code: ServerErrorCodes.InvalidData,
    });
};  