/* eslint-disable no-invalid-this */
/* eslint-disable require-jsdoc */
import {iAmClient} from "../../../iAmClient";
import { FBQueryDocumentSnapshot } from "../../../Mapping/firebaseMapping";
import {dbActions} from "../../Functions/dbActions";
import {AuthDoc} from "../../Interfaces/AuthDoc";
import {FBaseModelDoc} from "../../Interfaces/BaseDocs/FBaseModelDoc";
import {FDescriptorDoc} from "../../Interfaces/BaseDocs/FDescriptorDoc";
import {FSubDescriptorDoc} from "../../Interfaces/BaseDocs/FSubDescriptor";
import {FBaseModelInterface}
  from "../../Interfaces/Models/FBaseModelInterface";
import {ModelFields} from "../../Interfaces/Models/ModelFields";
import {ModelTypes} from "../../Interfaces/Models/ModelTypes";
import {FBQueryDoc} from "../../Interfaces/Query/FBQueryDoc";
import {CreateDocumentRequestDoc}
  from "../../Interfaces/ServerDocs/CreateDocumentRequestDoc";
import {GetDocumentRequestDoc}
  from "../../Interfaces/ServerDocs/GetDocumentRequestDoc";
import {ListQueryRequestDoc}
  from "../../Interfaces/ServerDocs/ListQueryRequestDoc";
import {RemoveDocRequestDoc}
  from "../../Interfaces/ServerDocs/RemoveDocRequestDoc";
import {UpdateDocumentRequestDoc}
  from "../../Interfaces/ServerDocs/UpdateDocumentRequestDoc";
import {ServerParamsDoc} from "../../Interfaces/ServerParamsDoc";
import {isString} from "../../is/isString";
import FSubBaseModel from "./FSubBaseModel";

const modelType = ModelTypes.BaseModel;
class FBaseModel extends FSubBaseModel implements
     FBaseModelInterface {
    static collectionName: ModelTypes = modelType
    constructor(doc?: FBaseModelDoc) {
      super(doc);
      if (doc === null || doc === undefined) {
        this.modelType = modelType;
        this.modelPath = [modelType];
      }
    }
    static synthesizeDescriptorDoc(fuid: string): FDescriptorDoc {
      const {modelType, modelPath} = this.synthesizeSubDescriptorDoc();
      return {
        fuid,
        objectId: fuid,
        ObjectId: fuid,
        modelPath,
        modelType,
      };
    }
    get descriptorDoc(): FDescriptorDoc | null {
      const sub = this.subDescriptorDoc;
      if (sub) {
        const fuid = this.fuid;
        const objectId = this.objectId;
        if (isString(fuid) && isString(objectId)) {
          return {...sub, fuid, objectId, modelPath: this.modelPath};
        }
      }
      return null;
    }
    get title(): string | null {
      return this.getStringValue(ModelFields.title);
    }
    set title(value: string | null) {
      this.setStringValue(ModelFields.title, value);
    }
    deleteModel = async (
        authDoc: AuthDoc | null,
        serverParamsDoc: ServerParamsDoc
    ): Promise<FBaseModelDoc[]> => {
      if (this.descriptorDoc) {
        const requestDoc: RemoveDocRequestDoc =
          {descriptorDoc: this.descriptorDoc};
        return dbActions.removeDocument(authDoc, requestDoc, serverParamsDoc);
      }
      console.log("in delete model, no fuid");
      return [];
    }
    static synthesizeSubDescriptorDoc(): FSubDescriptorDoc {
      return {
        modelType: this.collectionName,
        modelPath: [this.collectionName],
      };
    }
    static async getFBList(
        authDoc: AuthDoc | null,
        queryDoc: FBQueryDoc,
        serverParams: ServerParamsDoc): Promise<FBaseModelDoc[]> {
      const requestDoc: ListQueryRequestDoc =
        {queryDoc, subDescriptor: this.synthesizeSubDescriptorDoc()};
      return dbActions.getList(authDoc, requestDoc, serverParams);
    }
    static async getFBDocument(
        authDoc: AuthDoc | null,
        fuid: string,
        serverParams: ServerParamsDoc): Promise<FBaseModelDoc[]> {
      const requestDoc: GetDocumentRequestDoc =
        {descriptorDoc: this.synthesizeDescriptorDoc(fuid)};
      return dbActions.getDocument(authDoc, requestDoc, serverParams);
    }
    static async deleteModel(
        authDoc: AuthDoc | null,
        fuid: string, serverParams: ServerParamsDoc): Promise<FBaseModelDoc[]> {
      const requestDoc: RemoveDocRequestDoc =
        {descriptorDoc: this.synthesizeDescriptorDoc(fuid)};
      return dbActions.removeDocument(authDoc, requestDoc, serverParams);
    }
    createModel = async (
        authDoc: AuthDoc | null,
        serverParams: ServerParamsDoc): Promise<FBaseModelDoc[]> => {
      const date = new Date();
      this.createdAt = date;
      this.updatedAt = date;
      this.fbTimestamp = date;
      if (this.subDescriptorDoc) {
        const requestDoc: CreateDocumentRequestDoc =
        {
          subDescriptor: this.subDescriptorDoc,
          newDoc: this.combinedDoc,
          fuid: null,
        };
        return dbActions.createDocument(authDoc, requestDoc, serverParams);
      } else {
        console.log("No subdescriptor for createModel");
        return Promise.resolve([]);
      }
    }
    createModelWithFuid = async (
        authDoc: AuthDoc | null,
        fuid: string,
        serverParams: ServerParamsDoc): Promise<FBaseModelDoc[]> => {
      const date = new Date();
      this.createdAt = date;
      this.updatedAt = date;
      this.fbTimestamp = date;
      if (this.subDescriptorDoc) {
        const requestDoc: CreateDocumentRequestDoc =
        {subDescriptor: this.subDescriptorDoc, newDoc: this.combinedDoc, fuid};
        return dbActions.createDocument(authDoc, requestDoc, serverParams);
      } else {
        console.log("No subdescriptor for createModelWithFUID");
        return Promise.resolve([]);
      }
    }
    updateModel = async (
        authDoc: AuthDoc | null,
        serverParams: ServerParamsDoc): Promise<FBaseModelDoc[]> => {
      if (this.fuid) {
        this.updatedAt = new Date();
        if (this.descriptorDoc) {
          const requestDoc: UpdateDocumentRequestDoc =
              {descriptorDoc: this.descriptorDoc, updateDoc: this.updateDoc};
          if (iAmClient) {
            return dbActions.updateModel(authDoc, requestDoc, serverParams);
          } else {
            return dbActions.updateDocument(authDoc, requestDoc, serverParams);
          }
        } else {
          // eslint-disable-next-line max-len
          console.log("Attempted to update A Model that doesnt have a descriptor");
          return Promise.resolve([]);
        }
      } else {
        console.log("Attempted to update A Model that doesnt have a fuid");
        return Promise.resolve([]);
      }
    }
    saveModel = async (
        authDoc: AuthDoc | null,
        serverParams: ServerParamsDoc): Promise<FBaseModelDoc[]> => {
      return this.fuid ?
        this.updateModel(
            authDoc, serverParams) : this.createModel(authDoc, serverParams);
    }
    hideModel = async (
        authDoc: AuthDoc | null,
        serverParams: ServerParamsDoc): Promise<FBaseModelDoc[]> => {
      this.visible = false;
      this.updatedAt = new Date();
      return this.updateModel(authDoc, serverParams);
    }
    static async getListOfSnapshots(
      authDoc: AuthDoc | null,
      queryDoc: FBQueryDoc,
      serverParamsDoc: ServerParamsDoc,  
    ): Promise<FBQueryDocumentSnapshot[]> {
      const requestDoc: ListQueryRequestDoc = {
        queryDoc,
        subDescriptor: this.synthesizeSubDescriptorDoc(),
      }
      return dbActions.getListOfSnapshots(authDoc, requestDoc, serverParamsDoc);
    }
}

export default FBaseModel;
