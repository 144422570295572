/* eslint-disable no-invalid-this */
/* eslint-disable require-jsdoc */
import {baseSecureURL} from "../../../config/cloudinary";
import {FBTimestamp} from "../../../Mapping/firebaseMapping";
import {AnyProperty, arrayTypes} from "../../Interfaces/AnyProperty";
import {FBaseModelDoc} from "../../Interfaces/BaseDocs/FBaseModelDoc";
import {FDescriptorDoc} from "../../Interfaces/BaseDocs/FDescriptorDoc";
import {FSubDescriptorDoc} from "../../Interfaces/BaseDocs/FSubDescriptor";
import {Bullet} from "../../Interfaces/Bullets";
import {ImageTypes} from "../../Interfaces/ImageTypes";
import {FGeolocationDocObj} from "../../Interfaces/Location/FGeolocationDocObj";
import {FLatLngDoc} from "../../Interfaces/Location/FLatLngDoc";
import {DescriptorDocAndFuidsDoc}
  from "../../Interfaces/Models/DescriptorDocAndFuidsDoc";
import {FNameDoc} from "../../Interfaces/Models/FNameDoc";
import {FSubBaseModelInterface}
  from "../../Interfaces/Models/FSubBaseModelInterface";
import {imageDocs} from "../../Interfaces/Models/imageDocs";
import {ModelFields} from "../../Interfaces/Models/ModelFields";
import {ModelTypes} from "../../Interfaces/Models/ModelTypes";
import {QuillRows} from "../../Interfaces/QuillInterfaces";
import {ICalDocPrimative} from "../../Interfaces/Scheduler/iCalDoc";
import {TimeObject} from "../../Interfaces/Scheduler/TimeObject";
import {StripeLineItem} from "../../Interfaces/Stripe/StripeLineItem";
import { StripeResponse } from "../../Interfaces/Stripe/StripeResponse";
import {isAnyProperty} from "../../is/isAnyProperty";
import {isBoolean} from "../../is/isBoolean";
import {isFDescriptorDoc} from "../../is/isFDescriptorDoc";
import {isModelTypes} from "../../is/isModelType";
import {isNumber} from "../../is/isNumber";
import {isString} from "../../is/isString";

const modelType = ModelTypes.BaseModel;
class FSubBaseModel implements FSubBaseModelInterface {
    static collectionName: ModelTypes = modelType
    currentDoc: AnyProperty
    updateDoc: AnyProperty = {}
    constructor(doc?: FBaseModelDoc) {
      this.currentDoc = doc || {};
      if (doc === undefined || doc === null) {
        const date = new Date();
        this.assigner = null;
        this.createdAt = date;
        this.modelType = modelType;
        this.modelPath = [modelType];
        this.modelVersion = 0;
        this.owner = null;
        this.parent = null;
        this.superParent = null;
        this.updatedAt = date;
        this.modelVersion = 0;
        this.visible = true;
        this.whiteList = [];
        this.hashtags = [];
      }
    }
    get assigner(): FDescriptorDoc | null {
      return this.getDescriptorDoc(ModelFields.assigner);
    }
    set assigner(descriptor: FDescriptorDoc | null) {
      this.setDescriptorDoc(ModelFields.assigner, descriptor);
    }
    get subDescriptorDoc(): FSubDescriptorDoc | null {
      return {
        modelPath: this.modelPath,
        modelType: this.modelType,
      };
    }
    get combinedDoc(): AnyProperty {
      return {...this.currentDoc, ...this.updateDoc};
    }
    get hashtags(): string[] {
      const candidate = this.getArrayValue(ModelFields.hashtags);
      if (Array.isArray(candidate)) {
        return candidate as string[];
      }
      const array: string[] = [];
      this.hashtags = array;
      return array;
    }
    set hashtags(array: string[]) {
      this.setArrayValue(ModelFields.hashtags, array);
    }
    addHashtag = (tag: string): void => {
      this.hashtags = [...this.hashtags, tag];
    }
    get createdAt(): Date | null {
      return this.getDateValue(ModelFields.createdAt);
    }
    set createdAt(date: Date | null) {
      this.setDateValue(ModelFields.createdAt, date);
    }
    get updatedAt(): Date | null {
      return this.getDateValue(ModelFields.updatedAt);
    }
    set updatedAt(date: Date | null) {
      this.setDateValue(ModelFields.updatedAt, date);
    }
    get editing(): boolean {
      return !!this.getBooleanValue(ModelFields.editing);
    }
    set editing(value: boolean) {
      this.setBooleanValue(ModelFields.editing, value);
    }
    get submitted(): boolean {
      return !!this.getBooleanValue(ModelFields.submitted);
    }
    set submitted(value: boolean) {
      this.setBooleanValue(ModelFields.submitted, value);
    }
    get fuid(): string | null {
      const candidate = this.getStringValue(ModelFields.fuid);
      return isString(candidate) ? candidate : null;
    }
    set fuid(value: string | null) {
      this.setStringValue(ModelFields.fuid, value);
      this.objectId = value;
    }
    get modelPath(): string[] {
      const candidate = this.getArrayValue(ModelFields.modelPath);
      if (Array.isArray(candidate)) {
        return candidate as string[];
      }
      const array: string[] = [];
      this.whiteList = array;
      return array;
    }
    set modelPath(array: string[]) {
      this.setArrayValue(ModelFields.modelPath, array);
    }
    get modelVersion(): number {
      return this.getNumberValue(ModelFields.modelVersion) || 0;
    }
    set modelVersion(version: number) {
      this.setNumberValue(ModelFields.modelVersion, version);
    }
    get objectId(): string | null {
      const candidate = this.getStringValue(ModelFields.objectId);
      return isString(candidate) ? candidate : null;
    }
    set objectId(value: string | null) {
      this.setStringValue(ModelFields.objectId, value);
    }
    get modelType(): ModelTypes {
      const candidate = this.getStringValue(ModelFields.modelType);
      if (isModelTypes(candidate)) {
        return candidate;
      }
      console.log("In FSubBaseModel, No model type");
      return ModelTypes.BaseModel;
    }
    set modelType(type: ModelTypes) {
      this.setStringValue(ModelFields.modelType, `${type}`);
    }
    get owner(): FDescriptorDoc | null {
      return this.getDescriptorDoc(ModelFields.owner);
    }
    set owner(descriptor: FDescriptorDoc | null) {
      this.setDescriptorDoc(ModelFields.owner, descriptor);
    }
    get originator(): FDescriptorDoc | null {
      return this.getDescriptorDoc(ModelFields.originator);
    }
    set originator(descriptor: FDescriptorDoc | null) {
      this.setDescriptorDoc(ModelFields.originator, descriptor);
    }
    get parent(): FDescriptorDoc | null {
      return this.getDescriptorDoc(ModelFields.parent);
    }
    set parent(descriptor: FDescriptorDoc | null) {
      this.setDescriptorDoc(ModelFields.parent, descriptor);
    }
    get superParent(): FDescriptorDoc | null {
      return this.getDescriptorDoc(ModelFields.superParent);
    }
    set superParent(descriptor: FDescriptorDoc | null) {
      this.setDescriptorDoc(ModelFields.superParent, descriptor);
    }
    get visible(): boolean {
      const candidate = this.getBooleanValue(ModelFields.visible);
      return !!candidate;
    }
    set visible(value: boolean) {
      this.setBooleanValue(ModelFields.visible, value);
    }
    get displayName(): string | null {
      return this.getStringValue(ModelFields.displayName);
    }
    set displayName(name: string | null) {
      this.setStringValue(ModelFields.displayName, name);
      this.displayNameLC = name;
    }
    get displayNameLC(): string | null {
      return this.getStringValue(ModelFields.displayNameLC);
    }
    set displayNameLC(value: string | null) {
      this.setStringValue(
          ModelFields.displayNameLC,
        value ? value.toLowerCase() : null);
    }
    get imageDocs(): imageDocs {
      const candidate = this.getObjectValue(ModelFields.imageDocs);
      if (isAnyProperty(candidate)) {
        return candidate as imageDocs;
      }
      const doc: imageDocs = {};
      this.imageDocs = doc;
      return doc;
    }
    set imageDocs(value: imageDocs) {
      this.setObjectValue(ModelFields.imageDocs, value);
    }
  getFileURLFromImageType = (type: ImageTypes): string | null => {
    const imageDoc = this.imageDocs[type];
    if (imageDoc) {
      const url = imageDoc[ModelFields.fileURL];
      if (isString(url)) {
        return url;
      }
      const publicId = imageDoc[ModelFields.publicId];
      if (isString(publicId)) {
        return `${baseSecureURL}/${publicId}`;
      }
    }
    return null;
  }
  addImageDoc = (name: string, doc: FBaseModelDoc): void => {
    const docs = this.imageDocs;
    docs[name] = doc;
    this.imageDocs = {...docs};
  }
  get whiteList(): string[] {
    const candidate = this.getArrayValue(ModelFields.whiteList);
    if (Array.isArray(candidate)) {
      return candidate as string[];
    }
    const array: string[] = [];
    this.whiteList = array;
    return array;
  }
  set whiteList(array: string[]) {
    this.setArrayValue(ModelFields.whiteList, array);
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getField = (_field: ModelFields): unknown => {
    // eslint-disable-next-line max-len
    console.log(`In FSubBaseModel.getField for modelType ${this.modelType} need to implement`);
    return null;
  }
    protected setDescriptorDoc = (
        field: ModelFields, doc: FDescriptorDoc | null): void => {
          if (isFDescriptorDoc(doc)) {
            const scrubbed: FDescriptorDoc = {
              fuid: doc.fuid,
              modelPath: doc.modelPath,
              modelType: doc.modelType,
              objectId: doc.objectId,
            }
            this.updateDoc[field] = scrubbed;
          } else {
            this.updateDoc[field] = null;
          }

    }
    protected getDescriptorDoc =
    (field: ModelFields): FDescriptorDoc | null => {
      const candidate = this.updateDoc[field] || this.currentDoc[field];
      if (isFDescriptorDoc(candidate)) {
        return candidate;
      }
      return null;
    }
    protected setDateValue = (field: ModelFields, date: Date | null): void => {
      if (date) {
        this.updateDoc[field] = date.getTime();
      } else {
        this.updateDoc[field] = null;
      }
    }
    protected getDateValue = (field: ModelFields): Date | null => {
      const candidate = this.updateDoc[field] || this.currentDoc[field];
      if (isNumber(candidate)) {
        return new Date(candidate);
      }
      return null;
    }
    protected setNumberValue =
    (field: ModelFields, value: number | null): void => {
      this.updateDoc[field] = value;
    }
    protected getNumberValue = (field: ModelFields): number | null => {
      const candidate = this.updateDoc[field] || this.currentDoc[field];
      return isNumber(candidate) ? candidate : null;
    }
    protected setArrayValue = (
        field: ModelFields,
        value: string[]
        | AnyProperty[] | number[] | boolean[]
        |FLatLngDoc[] | QuillRows | Bullet[] | StripeLineItem[]
    ): void => {
      this.updateDoc[field] = value;
    }
    protected getArrayValue = (
        field: ModelFields,
    ): AnyProperty[]
      | string[] | number[] | boolean[]
      | FLatLngDoc[] | arrayTypes | QuillRows | Bullet[] | StripeLineItem[] => {
      const candidate = this.updateDoc[field] || this.currentDoc[field];
      if (Array.isArray(candidate)) {
        return candidate;
      }
      return [];
    }
    protected setBooleanValue = (field: ModelFields, value: boolean): void => {
      this.updateDoc[field] = value;
    }
    protected getBooleanValue = (field: ModelFields): boolean | null => {
      let candidate = this.updateDoc[field];
      if (isBoolean(candidate)) {
        return candidate;
      }
      candidate = this.currentDoc[field];
      if (isBoolean(candidate)) {
        return !!candidate;
      }
      return null;
    }
    protected getStringValue = (field: ModelFields): string | null => {
      const candidate = this.updateDoc[field] || this.currentDoc[field];
      return isString(candidate) ? candidate : null;
    }
    protected setStringValue =
      (field: ModelFields, value: string | null): void => {
        this.updateDoc[field] = value;
      }
    protected getObjectValue = (
        field: ModelFields): AnyProperty
        | FDescriptorDoc
        | FBaseModelDoc
        | FGeolocationDocObj
        | imageDocs
        | null => {
      const candidate = this.updateDoc[field] || this.currentDoc[field];
      if (isAnyProperty(candidate)) {
        return candidate;
      }
      return null;
    }
    protected setObjectValue = (
        field: ModelFields,
        value: AnyProperty |
        FDescriptorDoc |
        FBaseModelDoc |
        FNameDoc |
        DescriptorDocAndFuidsDoc |
        ICalDocPrimative |
        TimeObject |
        StripeResponse |
        null
    ): void => {
      this.updateDoc[field] = value;
    }
    protected setTimestamp = (field: ModelFields, date: Date | null): void => {
      if (date) {
        this.updateDoc[field] = FBTimestamp.fromDate(date);
      } else {
        this.updateDoc[field] = null;
      }
    }
  protected getTimestamp = (field: ModelFields): Date | null => {
    const candidate = this.updateDoc[field] || this.currentDoc[field];
    if (isAnyProperty(candidate)) {
      const {_seconds, _nanoseconds} = candidate;
      if (isNumber(_seconds) && isNumber(_nanoseconds)) {
        return new FBTimestamp(_seconds, _nanoseconds).toDate();
      }
    }
    return null;
  }
  get fbTimestamp(): Date | null {
    return this.getTimestamp(ModelFields.fbTimestamp);
  }
  set fbTimestamp(value: Date | null) {
    this.setTimestamp(ModelFields.fbTimestamp, value);
  }
  get version(): number {
    return this.getNumberValue(ModelFields.version) || 0;
  }
  set version(version: number) {
    this.setNumberValue(ModelFields.version, version);
  }
  updateModelWithEdits = (edits: AnyProperty): boolean => {
    console.log("In updateModelWIthEdits. Not implemented", edits);
    return false;
  }
}

export default FSubBaseModel;
