import { useCallback, useEffect, useRef } from "react"
import { Unsubscribe, User } from "firebase/auth"
import { getCurrentUserUID, initializeAuth, logout } from "../firebase/authentication"
import { useStoreActions, useStoreState } from "../Store/StoreHooks"
import { AuthDoc } from "../Common/Interfaces/AuthDoc"
import { dbActions } from "../Common/Functions/dbActions"
import { RouterRoles } from "../Common/Interfaces/Navigation/RouterRoles"
import { FBaseModelDoc } from "../Common/Interfaces/BaseDocs/FBaseModelDoc"

// code: auth/email-already-in-use
// code: auth/wrong-password
// code: auth/user-not-found
const authDoc: AuthDoc | null = null
export const useMasterAuthenticateListener = (): void => {
    const listener = useRef<Unsubscribe | null>(null)
    // const setAuthUser = useStoreActions((actions) => actions.auth.setAuthUser)
    const setAuthInfo = useStoreActions((actions) => actions.auth.setAuthInfo)
    const operatingModeDoc = useStoreState((state) => state.operatingMode.operatingModeDoc)
    const getMyProfile = useCallback((user: User | null) => {
        if (user) {
            dbActions.getOrCreateMyProfile(authDoc, {
                operatingModeDoc,
                trace: [],
                adminCode: 'None',
                routerRole: RouterRoles.generic,
            })
            .then((docs) => {
                const userProfile: FBaseModelDoc | null = docs.length > 0 ? docs[0] : null
                setAuthInfo({ user, userProfile})
            })
            .catch((error) => {
                console.log('useMasterAuthenticateListener Got error getting Profile', error)
                setAuthInfo({ user: null, userProfile: null })
            })
        } else {
            setAuthInfo({user: null, userProfile: null})
        }
    }, [operatingModeDoc, setAuthInfo])
    const dataHandler = useCallback((user: User | null) => {
        // console.log('OnAuthStateChanged with user ', user) 
        getMyProfile(user)     
    }, [getMyProfile])
    const errorHandler = useCallback((error: Error) => {
        console.log('FirebaseAuth Listener Got error', error)
        setAuthInfo({user: null, userProfile: null})
        const uid = getCurrentUserUID()
        if (uid) {
            logout().catch((logoutError) => {
                console.log('logoutError', logoutError)
            })
        }    
    }, [setAuthInfo])
    const completedHandler = useCallback(() => {
        console.log('FirebaseAuth Listener completed') 
        setAuthInfo({user: null, userProfile: null})      
    }, [setAuthInfo])
    useEffect(() => {
        listener.current && listener.current()
        listener.current = initializeAuth(dataHandler, errorHandler, completedHandler)
        return () => {
            listener.current && listener.current()
        }
    }, [dataHandler, errorHandler, completedHandler])
}