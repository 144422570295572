import { useHistory, useParams } from "react-router-dom";
import { useEffect, useRef, useCallback } from "react";
import { isString } from "../../Common/is/isString";

const path = 'path';
const useRedirect = (): {name: string} => {
    const mounted = useRef<boolean>(true);
    useEffect(() => { return ()=> { mounted.current = false}}, []);
    const history = useHistory();
    const { name } = useParams<{name: string}>();
    useEffect(() => {
        const redirectPath = new URLSearchParams(history.location.search).get(path);
        if (isString(redirectPath)) { history.push(redirectPath);}
    }, [history]);
    const lookupName = useCallback(async() => {
        console.log("Lookup Name");
    }, []);
    useEffect(() => { lookupName()}, [lookupName, name]);
    return { name };
}
export default useRedirect;

/*
    const lookupName = useCallback(async() => {
        if (isString(name)) {
            const scrubbed = name.trim().toLowerCase();
            if (scrubbed.length > 3) {
                const wheres = [
                    { fieldPath: [ModelFields.displayNameLC], operator: FBWhereOperators.eq, value: scrubbed},
                    {fieldPath: [ModelFields.visibility], operator: FBWhereOperators.eq, value: true}, 
                ];
                const sortObjects = [{ path: [ModelFields.createdAt], order: FBSortOrder.asc}];
                FPublicProfile.listFBDescriptors({ wheres, sortObjects, limit: 2})
                    .then(result => {
                        if ((result.descriptors.length > 0) && (mounted.current)) {
                            history.push(routePaths.profilePage.pathFunction(result.descriptors[0]));
                        }
                    })
                    .catch(error => { console.error('Error in SplashPage2.useRedirect.lookupName', error)})
            }
        }
    }, [history, name]);
*/