import FError from "../Common/ErrorHandling/FError";
import { appVersion } from "../Common/Functions/appVersion";
import { AnyProperty } from "../Common/Interfaces/AnyProperty";
import { AuthDoc } from "../Common/Interfaces/AuthDoc";
import {FBaseModelDoc} from "../Common/Interfaces/BaseDocs/FBaseModelDoc";
import { RemoveDocRequestDoc } from "../Common/Interfaces/ServerDocs/RemoveDocRequestDoc";
import { ServerErrorCodes } from "../Common/Interfaces/ServerDocs/ServerErrorCodes";
import {ServerParamsDoc} from "../Common/Interfaces/ServerParamsDoc";
import { ServerPayloadDoc } from "../Common/Interfaces/ServerPayloadDoc";
import { ServerMethods } from "../Common/ServerMethods";
import { getBaseURL } from "../Common/ServerURLs/getBaseURL";
import { getPath } from "../FAxios/getPath";
import { AxiosMethodTypes } from "../Common/ServerMethodsObj/AxiosMethodTypes";
import { AxiosRequestInterface } from "../FAxios/AxiosRequestInterface";
import { fAxios } from "../FAxios/FAxios";

const method = ServerMethods.deleteDocument;
export const removeDocument = (
    _authDoc: AuthDoc | null,
    requestDoc: RemoveDocRequestDoc,
    serverParamsDoc: ServerParamsDoc): Promise<FBaseModelDoc[]> => {
    const payload: ServerPayloadDoc = {
        serverParamsDoc,
        appVersion: appVersion(),
        requestDoc: requestDoc as unknown as AnyProperty,
        method,
    }
    const baseURL = getBaseURL(method, serverParamsDoc)
    const path = getPath(method, serverParamsDoc);
    if (baseURL) {
        const {descriptorDoc: {modelType, fuid}} = requestDoc;
        const request: AxiosRequestInterface = {
            method: AxiosMethodTypes.put,
            path: `${path}/${modelType}/${fuid}`,
            baseURL: baseURL,
            postPayload: {payload},
            onUploadProgress: (progress: ProgressEvent): void => {
              console.log('handleRemoveDocument Progress', progress)
            },
        }
        console.log('In handleRemoveDOcument REquest is :', request)
        return fAxios<FBaseModelDoc[]>(request)
    }
    const message = {
        message: `Failed to get baseURL`,
        serverParamsDoc: serverParamsDoc || {},
    }
    throw new FError({
        message,
        method,
        code: ServerErrorCodes.InvalidData,
    });
};

/*
        const docRef: FBDocumentReference =
            doc(createCollectionRef(resolveFBPath(modelPath, serverParams)), fuid)
        return deleteDoc(docRef)
            .then(() => {
                return []
            })
            .catch((error) => {
                throw errorToErrorWithJSONMessage(error,
                    'fbActions.removeDocument', 'No_code') 
            })
*/
