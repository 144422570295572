import React, { FC } from 'react'
import { AppBar, Typography } from '@material-ui/core';
import clsx from 'clsx';
import useStyles from './useStyles';
import useRedirect from './useRedirect';
// import { useHistory } from 'react-router-dom';
// import routePaths from '../../AppRouter/routePaths';
import FPublicProfile from '../../Common/Classes/Models/FPublicProfile';
import { AppStoreButton2 } from './AppStoreButton2';

interface SplashPage3 {
    myProfile: FPublicProfile | null
    handleLoginDialog: (modal: "" | "login" | "register" | "resetPassword") => void
}
const SplashPage3: FC = () => {
    // const { handleLoginDialog, myProfile } = props;
    useRedirect();
    // const history = useHistory();
    const { appBar, center2, header, outerContainer, white } = useStyles();
/*
    const showAboutPage = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        history.push(routePaths.aboutPage.pathFunction());
    }, [history]);
    */
    return (
        <div className={outerContainer}>
                        <div className={center2}>
            <AppBar position="fixed" className={clsx(appBar, white)}>

            <Typography className={header} variant="h4" align="center">
                Shyne
            </Typography>   
         
                </AppBar>
            </div>
            <Typography className={header} variant="h4" align="center" color="primary">
                Hand Car Cleaning at Your Convenience
            </Typography>

            <div className={center2}>
                <AppStoreButton2 />
            </div>
        </div>
    )

}


export default SplashPage3;

/*
                <Grid item xs={12}>
                        <img style={{height: 400}} src={'./iphonescreen.png'} alt="messageImage" />
                </Grid>
*/
/*
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Card>
                    <CardMedia
                        style={{ height: 150,  width: 150 }}
                        image="/beard.png"
                        title="Shyne!"
                    />
                    <CardContent style={{ flex: '1 0 auto'}}><Typography color="primary" variant="h5">Evan Siegel</Typography></CardContent>
                </Card>
            </div>
*/