import {ClientMode} from "../Common/Interfaces/OperatingModes/ClientMode";
import {DBMode} from "../Common/Interfaces/OperatingModes/DBMode";
import {OperatingModeDoc}
  from "../Common/Interfaces/OperatingModes/OperatingModeDoc";
import {ServerMode} from "../Common/Interfaces/OperatingModes/ServerMode";

export const defaultOperatingMode = (): OperatingModeDoc => {
  return {
    clientMode: ClientMode.development,
    serverMode: ServerMode.development,
    dbMode: DBMode.development,
  };
};
