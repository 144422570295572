import React, {FC} from 'react';
import Typography from '@material-ui/core/Typography';
import useStyles from './useStyles';
import { privacyContent } from './privacyContent';

const PrivacyNoticePage: FC = () => {
    const {headerStyle, listStyle, textStyle, olStyle} = useStyles();
    return (
        <div className={listStyle}>
            <Typography align="center" className={headerStyle} color="primary" variant="h4">
                Privacy Notice
            </Typography>
            <ol className={olStyle}>
                {privacyContent.map(item => (
                    <li key={item.header}>
                        <Typography className={headerStyle} color="primary" variant="body1">{item.header}</Typography>
                        <ul>
                        {item.content.map(sentence => (
                            <li key={sentence}>
                                <Typography align="left" className={textStyle} color="primary" variant="body2">
                                    {sentence}
                                </Typography>
                            </li>
                        ))}
                        </ul>
                    </li>
                ))} 
            </ol>
        </div>
    );
}
export default PrivacyNoticePage;
