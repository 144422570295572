import React, {FC} from "react"

const iOSUrl = 'https://apps.apple.com/us/app/id1527841284';
const imgUrl = "http://linkmaker.itunes.apple.com/images/badges/en-us/badge_appstore-lrg.svg";
export const AppStoreButton2: FC = () => {
    
    return (
      <div>
        <a
          style = {{
            display: 'inline-block',
            overflow: 'hidden',
            background: 'url(' + imgUrl + ') no-repeat',
            width: 165,
            height: 40,
          }}
          href  = {iOSUrl}
          >
        </a>
      </div>
    )
  }