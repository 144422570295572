import FError from "../Common/ErrorHandling/FError";
import { ResponseCode } from "../Common/ErrorHandling/ResponseCode";
import { AuthDoc } from "../Common/Interfaces/AuthDoc";
import { FBaseModelDoc } from "../Common/Interfaces/BaseDocs/FBaseModelDoc";
import { ListQueryRequestDoc } from "../Common/Interfaces/ServerDocs/ListQueryRequestDoc";
import { ServerParamsDoc } from "../Common/Interfaces/ServerParamsDoc";
import { isFBaseModelDoc } from "../Common/is/isFBaseModelDoc";
import { FBQuerySnapshot, FBQueryDocumentSnapshot } from "../Mapping/firebaseMapping";
import { innerGetDocs } from "./innerGetDocs";

export const getList = async (
    _authDoc: AuthDoc | null,
    requestDoc: ListQueryRequestDoc,
    serverParams: ServerParamsDoc): Promise<FBaseModelDoc[]> => {
    const {subDescriptor: {modelPath}, queryDoc} = requestDoc;
    // console.log(`In getList, requiestDoc is: `, requestDoc);
    return innerGetDocs(
        modelPath, queryDoc, serverParams).then((snapshot: FBQuerySnapshot) => {
        const scrubbed: FBaseModelDoc[] = []
        if (!snapshot.empty) {
            snapshot.forEach((fbDocument: FBQueryDocumentSnapshot) => {
                if (fbDocument.exists()) {
                    const doc = fbDocument.data()
                    if (isFBaseModelDoc(doc)) {
                        scrubbed.push(doc)
                    } else {
                        console.log(`Document isnt BaseModelDoc ${JSON.stringify(doc)}`);
                    }
                } else {
                    console.log("document doesnt exists");
                }
            });
        } else {
            // console.log("In getList, empty result. QueryDoc:", queryDoc);
        }
        // console.log(`In getList, retruning scrubbed: ${JSON.stringify(scrubbed)}`);
        return scrubbed
    }).catch((error) => {
        console.log('Error in innerGetDocs with requestDoc', requestDoc);
        throw FError.fbErrortoFError(error, "FBActions.getList", ResponseCode.InternalServerError);
    })
};
