import React, { FC, useRef, useEffect} from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import PrivacyNoticePage from '../Pages/PrivacyNotice';
import SplashPage from '../Pages/SplashPage';

const SplashRouter: FC = () => {
    const mounted = useRef<boolean>(true);
    useEffect(() => { return () => { mounted.current = false; }}, []);
    return (
        <Switch>
            <Route path={"/privacy"} component={PrivacyNoticePage} />
            <Route path={"/"} 
                render={() => <SplashPage />} 
            />

            <Route path='/*'> <Redirect to="/" /> </Route>
        </Switch>               
    )
}

export default SplashRouter;

