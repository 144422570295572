import { getDocs } from "firebase/firestore"
import { resolveFBPath } from "../Common/Functions/PathGeneration/resolveFBPath"
import { FBQueryDoc } from "../Common/Interfaces/Query/FBQueryDoc"
import { ServerParamsDoc } from "../Common/Interfaces/ServerParamsDoc"
import { FBQuerySnapshot } from "../Mapping/firebaseMapping"
import { createQueryRef } from "./createQueryRef"

export const innerGetDocs = (
    modelPath: string[],
    queryDoc: FBQueryDoc,
    serverParams: ServerParamsDoc): Promise<FBQuerySnapshot> => {
    return getDocs(createQueryRef(resolveFBPath(modelPath, serverParams), queryDoc))
}