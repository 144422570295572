import { addDays, addMonths, addYears, getDate, getHours, getMinutes, getMonth, getYear, setDate, setHours, setMinutes, setMonth, setSeconds, setYear } from "date-fns"
import { useCallback, useState } from "react"
import { zeroMilliseconds } from "../../../../../Calendar/zeroMilliseconds"
import { DateComponents } from "./DateComponents"

const base = zeroMilliseconds(setSeconds(setMinutes(new Date(), 0), 0))
export const assembleDate = (year: number, month: number, dateDay: number, hours: number, minutes = 0): Date => {
    return zeroMilliseconds(setSeconds(setMinutes(setHours(setDate(setMonth(setYear(new Date(), year), month), dateDay),hours),minutes), 0))
}

export const assembleDate2 = (components: DateComponents): Date => {
    const {year, month, day, hours, minutes} = components
    return zeroMilliseconds(setSeconds(setMinutes(setHours(setDate(setMonth(setYear(new Date(), year), month), day),hours),minutes), 0))
}

export const createDateObj = (date: Date): DateComponents => {
    return{day: getDate(date), month: getMonth(date), year: getYear(date), hours: getHours(date), minutes: getMinutes(date)}
}
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useDateInputs = (startDate?: Date) => {
    const [{day, month, year, hours, minutes}, innerSet] =
        useState<DateComponents>(createDateObj(startDate || base))
    const setDateMinutes = useCallback((minutes: number) => {
        innerSet(createDateObj(assembleDate(year, month, day, hours, minutes)))
    }, [day, month, year, hours, innerSet])
    const setDateHours = useCallback((hours: number) => {
        innerSet(createDateObj(assembleDate(year, month, day, hours, minutes)))
    }, [day, month, year, minutes, innerSet])
    const setDateDay = useCallback((dateDay: number) => {
        innerSet(createDateObj(assembleDate(year, month, dateDay, hours, minutes)))
    }, [month, year, hours, minutes, innerSet])
    const setDateMonth = useCallback((month: number) => {
        innerSet(createDateObj(assembleDate(year, month, day, hours, minutes)))
    }, [day, year, hours, minutes, innerSet])
    const setDateYear = useCallback((year: number) => {
        innerSet(createDateObj(assembleDate(year, month, day, hours, minutes)))
    }, [day, month, hours, minutes, innerSet])
    const incrementDateDay = useCallback((dateDay = 1) => {
        innerSet(createDateObj(addDays(assembleDate(year, month, day, hours, minutes), dateDay)))
    }, [day, month, year, hours, minutes, innerSet])
    const incrementDateMonth = useCallback((monthInc = 1) => {
        innerSet(createDateObj(addMonths(assembleDate(year, month, day, hours, minutes), monthInc)))
    }, [day, month, year, hours, minutes, innerSet])
    const incrementDateYear = useCallback((yearInc = 1) => {
        innerSet(createDateObj(addYears(assembleDate(year, month, day, hours, minutes), yearInc)))
    }, [day, month, year, hours, minutes, innerSet])
    return {day, month, year, hours, minutes, 
        setDateMinutes, setDateHours, setDateDay, setDateMonth, setDateYear,
        incrementDateDay, incrementDateMonth, incrementDateYear
    }
}