import FError from "../Common/ErrorHandling/FError";
import { appVersion } from "../Common/Functions/appVersion";
import { AnyProperty } from "../Common/Interfaces/AnyProperty";
import {AuthDoc} from "../Common/Interfaces/AuthDoc";
import {FBaseModelDoc} from "../Common/Interfaces/BaseDocs/FBaseModelDoc";
import { ServerErrorCodes } from "../Common/Interfaces/ServerDocs/ServerErrorCodes";
import {ServerParamsDoc} from "../Common/Interfaces/ServerParamsDoc";
import { ServerPayloadDoc } from "../Common/Interfaces/ServerPayloadDoc";
import { ServerMethods } from "../Common/ServerMethods";
import { getBaseURL } from "../Common/ServerURLs/getBaseURL";
import { getPath } from "../FAxios/getPath";
import { AxiosMethodTypes } from "../Common/ServerMethodsObj/AxiosMethodTypes";
import { AxiosRequestInterface } from "../FAxios/AxiosRequestInterface";
import { fAxios } from "../FAxios/FAxios";
import { GetOrCreateRatingRequestDoc } from "../Common/Interfaces/ServerDocs/Rating/GetOrCreateRatingRequestDoc";

const method = ServerMethods.getOrCreateRating;
export const getOrCreateRating = async (
    _authDoc: AuthDoc | null,
    requestDoc: GetOrCreateRatingRequestDoc,
    serverParamsDoc: ServerParamsDoc
): Promise<FBaseModelDoc[]> => {
  const payload: ServerPayloadDoc = {
    serverParamsDoc,
    appVersion: appVersion(),
    requestDoc: requestDoc as unknown as AnyProperty,
    method,
}
const baseURL = getBaseURL(method, serverParamsDoc)
const path = getPath(method, serverParamsDoc);
// console.log('GetOrCreateRating, path', path)
if (baseURL && path) {
    const request: AxiosRequestInterface = {
        method: AxiosMethodTypes.post,
        path,
        baseURL: baseURL,
        postPayload: {payload},
    }
    return fAxios<FBaseModelDoc[]>(request)
}
const message = {
  message: `Failed to get baseURL`,
  serverParamsDoc: serverParamsDoc || {},
}
  throw new FError({
    message,
    method,
    code: ServerErrorCodes.InvalidData,
  });
};
