export interface PrivacyContentInterface {
    header: string,
    content: string[],
}

export const privacyContent: PrivacyContentInterface[] = [
    {
        header: 'Introduction',
        content: [
            'When you use Shyne, you trust us with your personal data. We’re committed to keeping that trust. That starts with helping you understand our privacy practices.',
            'This notice describes the personal data we collect, and how it’s used and shared.',
            'Last modified August 13, 2020',
        ]
    }, {
        header: 'Overview',
        content: [
            'This notice describes how Shyne collects and uses personal data. This notice applies to all users of our apps, websites, features, or other services.'
        ]
    }, {
        header: 'The data we collect',
        content: [
            'User profile: We collect data when users create or update their Shyne accounts. This may include their name, email, phone number, login name and password, address, and profile picture.',
            'Communications data: We enable users to communicate with each other and Shyne through Shyne’s mobile apps and websites.',
        ],
    }, {
        header: 'How we use personal data',
        content: [
            'In short, we don`\'t share your data or use your data for anything other than for the services that we provide!',
        ]
    }, {
        header: 'Cookies and third-party technologies',
        content: [
            'Shyne uses cookies and other identification technologies on our apps, websites, and emails for purposes described in this notice.',
            'Cookies are small text files that are stored on browsers or devices by websites, apps, online media, and advertisements. Shyne uses cookies and similar technologies for purposes such as: authenticating users and remembering user preferences and settings.'
        ]
    },{
        header: 'Data retention and deletion',
        content: [
            'Shyne retains user profile, transaction, and other personal data for as long as a user maintains their Shyne account.'
        ]
    },{
        header: 'Choice and transparency',
        content: [
            'Device Permissions: Most mobile device platforms (iOS, Android, etc.) have defined certain types of device data that apps cannot access without the device owner’s permission, and these platforms have different methods for how that permission can be obtained. iOS devices notify users the first time the Shyne app requests permission to access certain types of data and gives users the option to grant or refuse permission. Android devices notify users of the permissions that the Shyne app seeks before their first use of the app, and use of the app constitutes a grant of such permission.',
        ]
    }, {
        header: 'Updates to this notice',
        content: [
            'We may occasionally update this notice. Use of our services after an update constitutes consent to the updated notice to the extent permitted by law.'
        ]
    }
]