import React, { ReactElement } from 'react';
import { useMasterAuthenticateListener } from '../Authenticate/useMasterAuthenticateListener';
import { BrowserRouter } from 'react-router-dom';
import SplashRouter from '../SplashRouter';

const App = (): ReactElement => {
  useMasterAuthenticateListener()
  return (
    <BrowserRouter>
      <SplashRouter  />
    </BrowserRouter>
  );
}

export default App;
