import {dbActions} from "../../Functions/dbActions";
import {AuthDoc} from "../../Interfaces/AuthDoc";
import {FBaseModelDoc} from "../../Interfaces/BaseDocs/FBaseModelDoc";
import {ModelTypes} from "../../Interfaces/Models/ModelTypes";
import {BusinessPersonAtDomainRequestDoc}
  from "../../Interfaces/ServerDocs/Business/BusinessPersonAtDomainRequestDoc";
import {ServerParamsDoc} from "../../Interfaces/ServerParamsDoc";
import FOwner from "./FOwner";

const modelType = ModelTypes.Provider;
/* eslint-disable require-jsdoc */
class FProvider extends FOwner {
    static collectionName: ModelTypes = modelType
    constructor(doc?: FBaseModelDoc) {
      super(doc);
      if (doc === undefined || doc === null) {
        this.modelType = modelType;
        this.modelPath = [modelType];
      }
    }
    static async lookupBusinessPersonAtDomain(
        authDoc: AuthDoc | null,
        businessDomain: string,
        serverParamsDoc: ServerParamsDoc): Promise<FBaseModelDoc[]> {
      const requestDoc: BusinessPersonAtDomainRequestDoc = {
        businessDomain, businessPerson: ModelTypes.Provider};
      return dbActions.
          lookupBusinessPersonAtDomain(authDoc, requestDoc, serverParamsDoc);
    }
}

export default FProvider;
