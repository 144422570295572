import { addDays, getDay, setDate, setHours, setMinutes, setMonth, setSeconds, setYear } from "date-fns"
import { zeroMilliseconds } from "../../../../../Calendar/zeroMilliseconds"

export enum DaysOfTheWeek {
    Sunday = 'Sunday',
    Monday = 'Monday',
    Tuesday = 'Tuesday',
    Wednesday = 'Wednesday',
    Thursday = 'Thursday',
    Friday = 'Friday',
    Saturday = 'Saturday'
}

export const DaysOfTheWeekArray = [
    DaysOfTheWeek.Sunday, DaysOfTheWeek.Monday, DaysOfTheWeek.Tuesday,
    DaysOfTheWeek.Wednesday, DaysOfTheWeek.Thursday, DaysOfTheWeek.Friday,
    DaysOfTheWeek.Saturday,
]

export const daysArray: {[property: string]: DaysOfTheWeek[]} = {
    [`${DaysOfTheWeek.Sunday}`]: DaysOfTheWeekArray,
    [`${DaysOfTheWeek.Monday}`]: [
        DaysOfTheWeek.Monday, DaysOfTheWeek.Tuesday,
        DaysOfTheWeek.Wednesday, DaysOfTheWeek.Thursday, DaysOfTheWeek.Friday,
        DaysOfTheWeek.Saturday, DaysOfTheWeek.Sunday, 
    ],
    [`${DaysOfTheWeek.Tuesday}`]: [
        DaysOfTheWeek.Tuesday,
        DaysOfTheWeek.Wednesday, DaysOfTheWeek.Thursday, DaysOfTheWeek.Friday,
        DaysOfTheWeek.Saturday, DaysOfTheWeek.Sunday, DaysOfTheWeek.Monday,
    ],
    [`${DaysOfTheWeek.Wednesday}`]: [
        DaysOfTheWeek.Wednesday, DaysOfTheWeek.Thursday, DaysOfTheWeek.Friday,
        DaysOfTheWeek.Saturday, DaysOfTheWeek.Sunday, DaysOfTheWeek.Monday,
        DaysOfTheWeek.Tuesday,
    ],
    [`${DaysOfTheWeek.Thursday}`]: [
        DaysOfTheWeek.Thursday, DaysOfTheWeek.Friday,
        DaysOfTheWeek.Saturday, DaysOfTheWeek.Sunday, DaysOfTheWeek.Monday,
        DaysOfTheWeek.Tuesday, DaysOfTheWeek.Wednesday, 
    ],
    [`${DaysOfTheWeek.Friday}`]: [
        DaysOfTheWeek.Friday,
        DaysOfTheWeek.Saturday, DaysOfTheWeek.Sunday, DaysOfTheWeek.Monday,
        DaysOfTheWeek.Tuesday, DaysOfTheWeek.Wednesday, DaysOfTheWeek.Thursday,
    ],
    [`${DaysOfTheWeek.Saturday}`]: [
        DaysOfTheWeek.Saturday, DaysOfTheWeek.Sunday, DaysOfTheWeek.Monday,
        DaysOfTheWeek.Tuesday, DaysOfTheWeek.Wednesday, DaysOfTheWeek.Thursday,
        DaysOfTheWeek.Friday,
    ]
}
export const dayToNumber = (day: DaysOfTheWeek): number => {
    if (day === DaysOfTheWeek.Sunday) {
        return 0
    } else if (day === DaysOfTheWeek.Monday) {
        return 1
    } else if (day === DaysOfTheWeek.Tuesday) {
        return 2
    } else if (day === DaysOfTheWeek.Wednesday) {
        return 3
    } else if (day === DaysOfTheWeek.Thursday) {
        return 4
    } else if (day === DaysOfTheWeek.Friday) {
        return 5
    }
    return 6
}

export const getAdjustedDate = (candidate: Date, baseDay: DaysOfTheWeek): Date => {
    const candidateDay = getDay(candidate)
    const baseDayIndex = dayToNumber(baseDay)
    if (candidateDay === baseDayIndex) {
        return zeroMilliseconds(candidate)
    } else if (baseDayIndex < candidateDay) {
        return addDays(zeroMilliseconds(candidate), (baseDayIndex - candidateDay))
    }
    return addDays(zeroMilliseconds(candidate), (baseDayIndex - candidateDay - 7))
}

export const getMonthBase = (year: number, month: number, baseDay: DaysOfTheWeek): Date => {
    // const candidateDate = zeroMilliseconds(setSeconds(setDate(setHours(setMonth(new Date(), month), 0), 0), 0))
    const candidateDate = zeroMilliseconds(setSeconds(setMinutes(setHours(setDate(setMonth(setYear(new Date(), year), month), 1), 0), 0),0))
    const candidateDay = getDay(candidateDate)
    const baseDayIndex = dayToNumber(baseDay)
    if (candidateDay === baseDayIndex) {
        return candidateDate
    } else if (candidateDay > baseDayIndex) {
        return addDays(candidateDate, (baseDayIndex - candidateDay))
    }
    return addDays(candidateDate, (baseDayIndex - candidateDay - 7))
}
