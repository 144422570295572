import {AnyProperty} from "../Interfaces/AnyProperty";

const tos = typeof "";
const ton = typeof 1;
const tob = typeof true;
const too = typeof {};

export const isAnyProperty = (candidate: unknown): candidate is AnyProperty => {
  if (candidate !== undefined && candidate !== null) {
    if (!Array.isArray(candidate)) {
      const toc = typeof candidate;
      if (toc === tos || toc === ton || toc === tob) {
        return false;
      }
      return toc === too;
    }
  }
  return false;
};
