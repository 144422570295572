import { AnyProperty } from "../Common/Interfaces/AnyProperty";
import { RouterRoles } from "../Common/Interfaces/Navigation/RouterRoles";
import { defaultOperatingMode } from "../config/defaultOperatingMode";
import { CRUDPaths } from "./CRUDPaths";
import { NavigationMode } from "./NavigationMode";
import { NavigationProps } from "./NavigationProps";
import { StackObj } from "./StackObj";
import { TypeOfPerson } from "./TypeOfPerson";

const stack: StackObj[] = []
const trace: AnyProperty[] = []
export const fakeNavigationProps: NavigationProps = {
    authDoc: null,
    routerRole: RouterRoles.generic,
    adminCode: null,
    operatingModeDoc: defaultOperatingMode(),
    trace,
    businessDomain: null,
    errors: {},
    level: 0,
    loading: {},
    maxLevel: 0,
    navigationMode: NavigationMode.screen,
    refresh: (): void => {
        console.log('fake refresh')
    },
    removeLevel: (level: number): void => {
        console.log('fake remove', level)
    },
    replaceLevel: (obj: StackObj, level: number):void => {
        console.log('fake replace', obj, level)
    },
    timestamp: 0,
    componentMode: CRUDPaths.create,
    typeOfPerson: TypeOfPerson.customer,
    remainingArea: {height: 0, width: 0},
    stack,
    setError: null,
    setLoading: null,
}
